export const arraycube = [
    {
        "x": -14,
        "y": -14,
        "z": -14
    },
    {
        "x": -14,
        "y": -14,
        "z": -10.5
    },
    {
        "x": -14,
        "y": -14,
        "z": -7
    },
    {
        "x": -14,
        "y": -14,
        "z": -3.5
    },
    {
        "x": -14,
        "y": -14,
        "z": 0
    },
    {
        "x": -14,
        "y": -14,
        "z": 3.5
    },
    {
        "x": -14,
        "y": -14,
        "z": 7
    },
    {
        "x": -14,
        "y": -14,
        "z": 10.5
    },
    {
        "x": -14,
        "y": -14,
        "z": 14
    },
    {
        "x": -14,
        "y": -10.5,
        "z": -14
    },
    {
        "x": -14,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": -14,
        "y": -10.5,
        "z": -7
    },
    {
        "x": -14,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": -14,
        "y": -10.5,
        "z": 0
    },
    {
        "x": -14,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": -14,
        "y": -10.5,
        "z": 7
    },
    {
        "x": -14,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": -14,
        "y": -10.5,
        "z": 14
    },
    {
        "x": -14,
        "y": -7,
        "z": -14
    },
    {
        "x": -14,
        "y": -7,
        "z": -10.5
    },
    {
        "x": -14,
        "y": -7,
        "z": -7
    },
    {
        "x": -14,
        "y": -7,
        "z": -3.5
    },
    {
        "x": -14,
        "y": -7,
        "z": 0
    },
    {
        "x": -14,
        "y": -7,
        "z": 3.5
    },
    {
        "x": -14,
        "y": -7,
        "z": 7
    },
    {
        "x": -14,
        "y": -7,
        "z": 10.5
    },
    {
        "x": -14,
        "y": -7,
        "z": 14
    },
    {
        "x": -14,
        "y": -3.5,
        "z": -14
    },
    {
        "x": -14,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": -14,
        "y": -3.5,
        "z": -7
    },
    {
        "x": -14,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": -14,
        "y": -3.5,
        "z": 0
    },
    {
        "x": -14,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": -14,
        "y": -3.5,
        "z": 7
    },
    {
        "x": -14,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": -14,
        "y": -3.5,
        "z": 14
    },
    {
        "x": -14,
        "y": 0,
        "z": -14
    },
    {
        "x": -14,
        "y": 0,
        "z": -10.5
    },
    {
        "x": -14,
        "y": 0,
        "z": -7
    },
    {
        "x": -14,
        "y": 0,
        "z": -3.5
    },
    {
        "x": -14,
        "y": 0,
        "z": 0
    },
    {
        "x": -14,
        "y": 0,
        "z": 3.5
    },
    {
        "x": -14,
        "y": 0,
        "z": 7
    },
    {
        "x": -14,
        "y": 0,
        "z": 10.5
    },
    {
        "x": -14,
        "y": 0,
        "z": 14
    },
    {
        "x": -14,
        "y": 3.5,
        "z": -14
    },
    {
        "x": -14,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": -14,
        "y": 3.5,
        "z": -7
    },
    {
        "x": -14,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": -14,
        "y": 3.5,
        "z": 0
    },
    {
        "x": -14,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": -14,
        "y": 3.5,
        "z": 7
    },
    {
        "x": -14,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": -14,
        "y": 3.5,
        "z": 14
    },
    {
        "x": -14,
        "y": 7,
        "z": -14
    },
    {
        "x": -14,
        "y": 7,
        "z": -10.5
    },
    {
        "x": -14,
        "y": 7,
        "z": -7
    },
    {
        "x": -14,
        "y": 7,
        "z": -3.5
    },
    {
        "x": -14,
        "y": 7,
        "z": 0
    },
    {
        "x": -14,
        "y": 7,
        "z": 3.5
    },
    {
        "x": -14,
        "y": 7,
        "z": 7
    },
    {
        "x": -14,
        "y": 7,
        "z": 10.5
    },
    {
        "x": -14,
        "y": 7,
        "z": 14
    },
    {
        "x": -14,
        "y": 10.5,
        "z": -14
    },
    {
        "x": -14,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": -14,
        "y": 10.5,
        "z": -7
    },
    {
        "x": -14,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": -14,
        "y": 10.5,
        "z": 0
    },
    {
        "x": -14,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": -14,
        "y": 10.5,
        "z": 7
    },
    {
        "x": -14,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": -14,
        "y": 10.5,
        "z": 14
    },
    {
        "x": -14,
        "y": 14,
        "z": -14
    },
    {
        "x": -14,
        "y": 14,
        "z": -10.5
    },
    {
        "x": -14,
        "y": 14,
        "z": -7
    },
    {
        "x": -14,
        "y": 14,
        "z": -3.5
    },
    {
        "x": -14,
        "y": 14,
        "z": 0
    },
    {
        "x": -14,
        "y": 14,
        "z": 3.5
    },
    {
        "x": -14,
        "y": 14,
        "z": 7
    },
    {
        "x": -14,
        "y": 14,
        "z": 10.5
    },
    {
        "x": -14,
        "y": 14,
        "z": 14
    },
    {
        "x": -10.5,
        "y": -14,
        "z": -14
    },
    {
        "x": -10.5,
        "y": -14,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": -14,
        "z": -7
    },
    {
        "x": -10.5,
        "y": -14,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": -14,
        "z": 0
    },
    {
        "x": -10.5,
        "y": -14,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": -14,
        "z": 7
    },
    {
        "x": -10.5,
        "y": -14,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": -14,
        "z": 14
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": -14
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": -7
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": 0
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": 7
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": -10.5,
        "z": 14
    },
    {
        "x": -10.5,
        "y": -7,
        "z": -14
    },
    {
        "x": -10.5,
        "y": -7,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": -7,
        "z": -7
    },
    {
        "x": -10.5,
        "y": -7,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": -7,
        "z": 0
    },
    {
        "x": -10.5,
        "y": -7,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": -7,
        "z": 7
    },
    {
        "x": -10.5,
        "y": -7,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": -7,
        "z": 14
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": -14
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": -7
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": 0
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": 7
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": -3.5,
        "z": 14
    },
    {
        "x": -10.5,
        "y": 0,
        "z": -14
    },
    {
        "x": -10.5,
        "y": 0,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": 0,
        "z": -7
    },
    {
        "x": -10.5,
        "y": 0,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": 0,
        "z": 0
    },
    {
        "x": -10.5,
        "y": 0,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": 0,
        "z": 7
    },
    {
        "x": -10.5,
        "y": 0,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": 0,
        "z": 14
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": -14
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": -7
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": 0
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": 7
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": 3.5,
        "z": 14
    },
    {
        "x": -10.5,
        "y": 7,
        "z": -14
    },
    {
        "x": -10.5,
        "y": 7,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": 7,
        "z": -7
    },
    {
        "x": -10.5,
        "y": 7,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": 7,
        "z": 0
    },
    {
        "x": -10.5,
        "y": 7,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": 7,
        "z": 7
    },
    {
        "x": -10.5,
        "y": 7,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": 7,
        "z": 14
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": -14
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": -7
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": 0
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": 7
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": 10.5,
        "z": 14
    },
    {
        "x": -10.5,
        "y": 14,
        "z": -14
    },
    {
        "x": -10.5,
        "y": 14,
        "z": -10.5
    },
    {
        "x": -10.5,
        "y": 14,
        "z": -7
    },
    {
        "x": -10.5,
        "y": 14,
        "z": -3.5
    },
    {
        "x": -10.5,
        "y": 14,
        "z": 0
    },
    {
        "x": -10.5,
        "y": 14,
        "z": 3.5
    },
    {
        "x": -10.5,
        "y": 14,
        "z": 7
    },
    {
        "x": -10.5,
        "y": 14,
        "z": 10.5
    },
    {
        "x": -10.5,
        "y": 14,
        "z": 14
    },
    {
        "x": -7,
        "y": -14,
        "z": -14
    },
    {
        "x": -7,
        "y": -14,
        "z": -10.5
    },
    {
        "x": -7,
        "y": -14,
        "z": -7
    },
    {
        "x": -7,
        "y": -14,
        "z": -3.5
    },
    {
        "x": -7,
        "y": -14,
        "z": 0
    },
    {
        "x": -7,
        "y": -14,
        "z": 3.5
    },
    {
        "x": -7,
        "y": -14,
        "z": 7
    },
    {
        "x": -7,
        "y": -14,
        "z": 10.5
    },
    {
        "x": -7,
        "y": -14,
        "z": 14
    },
    {
        "x": -7,
        "y": -10.5,
        "z": -14
    },
    {
        "x": -7,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": -7,
        "y": -10.5,
        "z": -7
    },
    {
        "x": -7,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": -7,
        "y": -10.5,
        "z": 0
    },
    {
        "x": -7,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": -7,
        "y": -10.5,
        "z": 7
    },
    {
        "x": -7,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": -7,
        "y": -10.5,
        "z": 14
    },
    {
        "x": -7,
        "y": -7,
        "z": -14
    },
    {
        "x": -7,
        "y": -7,
        "z": -10.5
    },
    {
        "x": -7,
        "y": -7,
        "z": -7
    },
    {
        "x": -7,
        "y": -7,
        "z": -3.5
    },
    {
        "x": -7,
        "y": -7,
        "z": 0
    },
    {
        "x": -7,
        "y": -7,
        "z": 3.5
    },
    {
        "x": -7,
        "y": -7,
        "z": 7
    },
    {
        "x": -7,
        "y": -7,
        "z": 10.5
    },
    {
        "x": -7,
        "y": -7,
        "z": 14
    },
    {
        "x": -7,
        "y": -3.5,
        "z": -14
    },
    {
        "x": -7,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": -7,
        "y": -3.5,
        "z": -7
    },
    {
        "x": -7,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": -7,
        "y": -3.5,
        "z": 0
    },
    {
        "x": -7,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": -7,
        "y": -3.5,
        "z": 7
    },
    {
        "x": -7,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": -7,
        "y": -3.5,
        "z": 14
    },
    {
        "x": -7,
        "y": 0,
        "z": -14
    },
    {
        "x": -7,
        "y": 0,
        "z": -10.5
    },
    {
        "x": -7,
        "y": 0,
        "z": -7
    },
    {
        "x": -7,
        "y": 0,
        "z": -3.5
    },
    {
        "x": -7,
        "y": 0,
        "z": 0
    },
    {
        "x": -7,
        "y": 0,
        "z": 3.5
    },
    {
        "x": -7,
        "y": 0,
        "z": 7
    },
    {
        "x": -7,
        "y": 0,
        "z": 10.5
    },
    {
        "x": -7,
        "y": 0,
        "z": 14
    },
    {
        "x": -7,
        "y": 3.5,
        "z": -14
    },
    {
        "x": -7,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": -7,
        "y": 3.5,
        "z": -7
    },
    {
        "x": -7,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": -7,
        "y": 3.5,
        "z": 0
    },
    {
        "x": -7,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": -7,
        "y": 3.5,
        "z": 7
    },
    {
        "x": -7,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": -7,
        "y": 3.5,
        "z": 14
    },
    {
        "x": -7,
        "y": 7,
        "z": -14
    },
    {
        "x": -7,
        "y": 7,
        "z": -10.5
    },
    {
        "x": -7,
        "y": 7,
        "z": -7
    },
    {
        "x": -7,
        "y": 7,
        "z": -3.5
    },
    {
        "x": -7,
        "y": 7,
        "z": 0
    },
    {
        "x": -7,
        "y": 7,
        "z": 3.5
    },
    {
        "x": -7,
        "y": 7,
        "z": 7
    },
    {
        "x": -7,
        "y": 7,
        "z": 10.5
    },
    {
        "x": -7,
        "y": 7,
        "z": 14
    },
    {
        "x": -7,
        "y": 10.5,
        "z": -14
    },
    {
        "x": -7,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": -7,
        "y": 10.5,
        "z": -7
    },
    {
        "x": -7,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": -7,
        "y": 10.5,
        "z": 0
    },
    {
        "x": -7,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": -7,
        "y": 10.5,
        "z": 7
    },
    {
        "x": -7,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": -7,
        "y": 10.5,
        "z": 14
    },
    {
        "x": -7,
        "y": 14,
        "z": -14
    },
    {
        "x": -7,
        "y": 14,
        "z": -10.5
    },
    {
        "x": -7,
        "y": 14,
        "z": -7
    },
    {
        "x": -7,
        "y": 14,
        "z": -3.5
    },
    {
        "x": -7,
        "y": 14,
        "z": 0
    },
    {
        "x": -7,
        "y": 14,
        "z": 3.5
    },
    {
        "x": -7,
        "y": 14,
        "z": 7
    },
    {
        "x": -7,
        "y": 14,
        "z": 10.5
    },
    {
        "x": -7,
        "y": 14,
        "z": 14
    },
    {
        "x": -3.5,
        "y": -14,
        "z": -14
    },
    {
        "x": -3.5,
        "y": -14,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": -14,
        "z": -7
    },
    {
        "x": -3.5,
        "y": -14,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": -14,
        "z": 0
    },
    {
        "x": -3.5,
        "y": -14,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": -14,
        "z": 7
    },
    {
        "x": -3.5,
        "y": -14,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": -14,
        "z": 14
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": -14
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": -7
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": 0
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": 7
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": -10.5,
        "z": 14
    },
    {
        "x": -3.5,
        "y": -7,
        "z": -14
    },
    {
        "x": -3.5,
        "y": -7,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": -7,
        "z": -7
    },
    {
        "x": -3.5,
        "y": -7,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": -7,
        "z": 0
    },
    {
        "x": -3.5,
        "y": -7,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": -7,
        "z": 7
    },
    {
        "x": -3.5,
        "y": -7,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": -7,
        "z": 14
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": -14
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": -7
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": 0
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": 7
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": -3.5,
        "z": 14
    },
    {
        "x": -3.5,
        "y": 0,
        "z": -14
    },
    {
        "x": -3.5,
        "y": 0,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": 0,
        "z": -7
    },
    {
        "x": -3.5,
        "y": 0,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": 0,
        "z": 0
    },
    {
        "x": -3.5,
        "y": 0,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": 0,
        "z": 7
    },
    {
        "x": -3.5,
        "y": 0,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": 0,
        "z": 14
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": -14
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": -7
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": 0
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": 7
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": 3.5,
        "z": 14
    },
    {
        "x": -3.5,
        "y": 7,
        "z": -14
    },
    {
        "x": -3.5,
        "y": 7,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": 7,
        "z": -7
    },
    {
        "x": -3.5,
        "y": 7,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": 7,
        "z": 0
    },
    {
        "x": -3.5,
        "y": 7,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": 7,
        "z": 7
    },
    {
        "x": -3.5,
        "y": 7,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": 7,
        "z": 14
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": -14
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": -7
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": 0
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": 7
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": 10.5,
        "z": 14
    },
    {
        "x": -3.5,
        "y": 14,
        "z": -14
    },
    {
        "x": -3.5,
        "y": 14,
        "z": -10.5
    },
    {
        "x": -3.5,
        "y": 14,
        "z": -7
    },
    {
        "x": -3.5,
        "y": 14,
        "z": -3.5
    },
    {
        "x": -3.5,
        "y": 14,
        "z": 0
    },
    {
        "x": -3.5,
        "y": 14,
        "z": 3.5
    },
    {
        "x": -3.5,
        "y": 14,
        "z": 7
    },
    {
        "x": -3.5,
        "y": 14,
        "z": 10.5
    },
    {
        "x": -3.5,
        "y": 14,
        "z": 14
    },
    {
        "x": 0,
        "y": -14,
        "z": -14
    },
    {
        "x": 0,
        "y": -14,
        "z": -10.5
    },
    {
        "x": 0,
        "y": -14,
        "z": -7
    },
    {
        "x": 0,
        "y": -14,
        "z": -3.5
    },
    {
        "x": 0,
        "y": -14,
        "z": 0
    },
    {
        "x": 0,
        "y": -14,
        "z": 3.5
    },
    {
        "x": 0,
        "y": -14,
        "z": 7
    },
    {
        "x": 0,
        "y": -14,
        "z": 10.5
    },
    {
        "x": 0,
        "y": -14,
        "z": 14
    },
    {
        "x": 0,
        "y": -10.5,
        "z": -14
    },
    {
        "x": 0,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": 0,
        "y": -10.5,
        "z": -7
    },
    {
        "x": 0,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": 0,
        "y": -10.5,
        "z": 0
    },
    {
        "x": 0,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": 0,
        "y": -10.5,
        "z": 7
    },
    {
        "x": 0,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": 0,
        "y": -10.5,
        "z": 14
    },
    {
        "x": 0,
        "y": -7,
        "z": -14
    },
    {
        "x": 0,
        "y": -7,
        "z": -10.5
    },
    {
        "x": 0,
        "y": -7,
        "z": -7
    },
    {
        "x": 0,
        "y": -7,
        "z": -3.5
    },
    {
        "x": 0,
        "y": -7,
        "z": 0
    },
    {
        "x": 0,
        "y": -7,
        "z": 3.5
    },
    {
        "x": 0,
        "y": -7,
        "z": 7
    },
    {
        "x": 0,
        "y": -7,
        "z": 10.5
    },
    {
        "x": 0,
        "y": -7,
        "z": 14
    },
    {
        "x": 0,
        "y": -3.5,
        "z": -14
    },
    {
        "x": 0,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": 0,
        "y": -3.5,
        "z": -7
    },
    {
        "x": 0,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": 0,
        "y": -3.5,
        "z": 0
    },
    {
        "x": 0,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": 0,
        "y": -3.5,
        "z": 7
    },
    {
        "x": 0,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": 0,
        "y": -3.5,
        "z": 14
    },
    {
        "x": 0,
        "y": 0,
        "z": -14
    },
    {
        "x": 0,
        "y": 0,
        "z": -10.5
    },
    {
        "x": 0,
        "y": 0,
        "z": -7
    },
    {
        "x": 0,
        "y": 0,
        "z": -3.5
    },
    {
        "x": 0,
        "y": 0,
        "z": 0
    },
    {
        "x": 0,
        "y": 0,
        "z": 3.5
    },
    {
        "x": 0,
        "y": 0,
        "z": 7
    },
    {
        "x": 0,
        "y": 0,
        "z": 10.5
    },
    {
        "x": 0,
        "y": 0,
        "z": 14
    },
    {
        "x": 0,
        "y": 3.5,
        "z": -14
    },
    {
        "x": 0,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": 0,
        "y": 3.5,
        "z": -7
    },
    {
        "x": 0,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": 0,
        "y": 3.5,
        "z": 0
    },
    {
        "x": 0,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": 0,
        "y": 3.5,
        "z": 7
    },
    {
        "x": 0,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": 0,
        "y": 3.5,
        "z": 14
    },
    {
        "x": 0,
        "y": 7,
        "z": -14
    },
    {
        "x": 0,
        "y": 7,
        "z": -10.5
    },
    {
        "x": 0,
        "y": 7,
        "z": -7
    },
    {
        "x": 0,
        "y": 7,
        "z": -3.5
    },
    {
        "x": 0,
        "y": 7,
        "z": 0
    },
    {
        "x": 0,
        "y": 7,
        "z": 3.5
    },
    {
        "x": 0,
        "y": 7,
        "z": 7
    },
    {
        "x": 0,
        "y": 7,
        "z": 10.5
    },
    {
        "x": 0,
        "y": 7,
        "z": 14
    },
    {
        "x": 0,
        "y": 10.5,
        "z": -14
    },
    {
        "x": 0,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": 0,
        "y": 10.5,
        "z": -7
    },
    {
        "x": 0,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": 0,
        "y": 10.5,
        "z": 0
    },
    {
        "x": 0,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": 0,
        "y": 10.5,
        "z": 7
    },
    {
        "x": 0,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": 0,
        "y": 10.5,
        "z": 14
    },
    {
        "x": 0,
        "y": 14,
        "z": -14
    },
    {
        "x": 0,
        "y": 14,
        "z": -10.5
    },
    {
        "x": 0,
        "y": 14,
        "z": -7
    },
    {
        "x": 0,
        "y": 14,
        "z": -3.5
    },
    {
        "x": 0,
        "y": 14,
        "z": 0
    },
    {
        "x": 0,
        "y": 14,
        "z": 3.5
    },
    {
        "x": 0,
        "y": 14,
        "z": 7
    },
    {
        "x": 0,
        "y": 14,
        "z": 10.5
    },
    {
        "x": 0,
        "y": 14,
        "z": 14
    },
    {
        "x": 3.5,
        "y": -14,
        "z": -14
    },
    {
        "x": 3.5,
        "y": -14,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": -14,
        "z": -7
    },
    {
        "x": 3.5,
        "y": -14,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": -14,
        "z": 0
    },
    {
        "x": 3.5,
        "y": -14,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": -14,
        "z": 7
    },
    {
        "x": 3.5,
        "y": -14,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": -14,
        "z": 14
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": -14
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": -7
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": 0
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": 7
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": -10.5,
        "z": 14
    },
    {
        "x": 3.5,
        "y": -7,
        "z": -14
    },
    {
        "x": 3.5,
        "y": -7,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": -7,
        "z": -7
    },
    {
        "x": 3.5,
        "y": -7,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": -7,
        "z": 0
    },
    {
        "x": 3.5,
        "y": -7,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": -7,
        "z": 7
    },
    {
        "x": 3.5,
        "y": -7,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": -7,
        "z": 14
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": -14
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": -7
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": 0
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": 7
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": -3.5,
        "z": 14
    },
    {
        "x": 3.5,
        "y": 0,
        "z": -14
    },
    {
        "x": 3.5,
        "y": 0,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": 0,
        "z": -7
    },
    {
        "x": 3.5,
        "y": 0,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": 0,
        "z": 0
    },
    {
        "x": 3.5,
        "y": 0,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": 0,
        "z": 7
    },
    {
        "x": 3.5,
        "y": 0,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": 0,
        "z": 14
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": -14
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": -7
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": 0
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": 7
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": 3.5,
        "z": 14
    },
    {
        "x": 3.5,
        "y": 7,
        "z": -14
    },
    {
        "x": 3.5,
        "y": 7,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": 7,
        "z": -7
    },
    {
        "x": 3.5,
        "y": 7,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": 7,
        "z": 0
    },
    {
        "x": 3.5,
        "y": 7,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": 7,
        "z": 7
    },
    {
        "x": 3.5,
        "y": 7,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": 7,
        "z": 14
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": -14
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": -7
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": 0
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": 7
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": 10.5,
        "z": 14
    },
    {
        "x": 3.5,
        "y": 14,
        "z": -14
    },
    {
        "x": 3.5,
        "y": 14,
        "z": -10.5
    },
    {
        "x": 3.5,
        "y": 14,
        "z": -7
    },
    {
        "x": 3.5,
        "y": 14,
        "z": -3.5
    },
    {
        "x": 3.5,
        "y": 14,
        "z": 0
    },
    {
        "x": 3.5,
        "y": 14,
        "z": 3.5
    },
    {
        "x": 3.5,
        "y": 14,
        "z": 7
    },
    {
        "x": 3.5,
        "y": 14,
        "z": 10.5
    },
    {
        "x": 3.5,
        "y": 14,
        "z": 14
    },
    {
        "x": 7,
        "y": -14,
        "z": -14
    },
    {
        "x": 7,
        "y": -14,
        "z": -10.5
    },
    {
        "x": 7,
        "y": -14,
        "z": -7
    },
    {
        "x": 7,
        "y": -14,
        "z": -3.5
    },
    {
        "x": 7,
        "y": -14,
        "z": 0
    },
    {
        "x": 7,
        "y": -14,
        "z": 3.5
    },
    {
        "x": 7,
        "y": -14,
        "z": 7
    },
    {
        "x": 7,
        "y": -14,
        "z": 10.5
    },
    {
        "x": 7,
        "y": -14,
        "z": 14
    },
    {
        "x": 7,
        "y": -10.5,
        "z": -14
    },
    {
        "x": 7,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": 7,
        "y": -10.5,
        "z": -7
    },
    {
        "x": 7,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": 7,
        "y": -10.5,
        "z": 0
    },
    {
        "x": 7,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": 7,
        "y": -10.5,
        "z": 7
    },
    {
        "x": 7,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": 7,
        "y": -10.5,
        "z": 14
    },
    {
        "x": 7,
        "y": -7,
        "z": -14
    },
    {
        "x": 7,
        "y": -7,
        "z": -10.5
    },
    {
        "x": 7,
        "y": -7,
        "z": -7
    },
    {
        "x": 7,
        "y": -7,
        "z": -3.5
    },
    {
        "x": 7,
        "y": -7,
        "z": 0
    },
    {
        "x": 7,
        "y": -7,
        "z": 3.5
    },
    {
        "x": 7,
        "y": -7,
        "z": 7
    },
    {
        "x": 7,
        "y": -7,
        "z": 10.5
    },
    {
        "x": 7,
        "y": -7,
        "z": 14
    },
    {
        "x": 7,
        "y": -3.5,
        "z": -14
    },
    {
        "x": 7,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": 7,
        "y": -3.5,
        "z": -7
    },
    {
        "x": 7,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": 7,
        "y": -3.5,
        "z": 0
    },
    {
        "x": 7,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": 7,
        "y": -3.5,
        "z": 7
    },
    {
        "x": 7,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": 7,
        "y": -3.5,
        "z": 14
    },
    {
        "x": 7,
        "y": 0,
        "z": -14
    },
    {
        "x": 7,
        "y": 0,
        "z": -10.5
    },
    {
        "x": 7,
        "y": 0,
        "z": -7
    },
    {
        "x": 7,
        "y": 0,
        "z": -3.5
    },
    {
        "x": 7,
        "y": 0,
        "z": 0
    },
    {
        "x": 7,
        "y": 0,
        "z": 3.5
    },
    {
        "x": 7,
        "y": 0,
        "z": 7
    },
    {
        "x": 7,
        "y": 0,
        "z": 10.5
    },
    {
        "x": 7,
        "y": 0,
        "z": 14
    },
    {
        "x": 7,
        "y": 3.5,
        "z": -14
    },
    {
        "x": 7,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": 7,
        "y": 3.5,
        "z": -7
    },
    {
        "x": 7,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": 7,
        "y": 3.5,
        "z": 0
    },
    {
        "x": 7,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": 7,
        "y": 3.5,
        "z": 7
    },
    {
        "x": 7,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": 7,
        "y": 3.5,
        "z": 14
    },
    {
        "x": 7,
        "y": 7,
        "z": -14
    },
    {
        "x": 7,
        "y": 7,
        "z": -10.5
    },
    {
        "x": 7,
        "y": 7,
        "z": -7
    },
    {
        "x": 7,
        "y": 7,
        "z": -3.5
    },
    {
        "x": 7,
        "y": 7,
        "z": 0
    },
    {
        "x": 7,
        "y": 7,
        "z": 3.5
    },
    {
        "x": 7,
        "y": 7,
        "z": 7
    },
    {
        "x": 7,
        "y": 7,
        "z": 10.5
    },
    {
        "x": 7,
        "y": 7,
        "z": 14
    },
    {
        "x": 7,
        "y": 10.5,
        "z": -14
    },
    {
        "x": 7,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": 7,
        "y": 10.5,
        "z": -7
    },
    {
        "x": 7,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": 7,
        "y": 10.5,
        "z": 0
    },
    {
        "x": 7,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": 7,
        "y": 10.5,
        "z": 7
    },
    {
        "x": 7,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": 7,
        "y": 10.5,
        "z": 14
    },
    {
        "x": 7,
        "y": 14,
        "z": -14
    },
    {
        "x": 7,
        "y": 14,
        "z": -10.5
    },
    {
        "x": 7,
        "y": 14,
        "z": -7
    },
    {
        "x": 7,
        "y": 14,
        "z": -3.5
    },
    {
        "x": 7,
        "y": 14,
        "z": 0
    },
    {
        "x": 7,
        "y": 14,
        "z": 3.5
    },
    {
        "x": 7,
        "y": 14,
        "z": 7
    },
    {
        "x": 7,
        "y": 14,
        "z": 10.5
    },
    {
        "x": 7,
        "y": 14,
        "z": 14
    },
    {
        "x": 10.5,
        "y": -14,
        "z": -14
    },
    {
        "x": 10.5,
        "y": -14,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": -14,
        "z": -7
    },
    {
        "x": 10.5,
        "y": -14,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": -14,
        "z": 0
    },
    {
        "x": 10.5,
        "y": -14,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": -14,
        "z": 7
    },
    {
        "x": 10.5,
        "y": -14,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": -14,
        "z": 14
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": -14
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": -7
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": 0
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": 7
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": -10.5,
        "z": 14
    },
    {
        "x": 10.5,
        "y": -7,
        "z": -14
    },
    {
        "x": 10.5,
        "y": -7,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": -7,
        "z": -7
    },
    {
        "x": 10.5,
        "y": -7,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": -7,
        "z": 0
    },
    {
        "x": 10.5,
        "y": -7,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": -7,
        "z": 7
    },
    {
        "x": 10.5,
        "y": -7,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": -7,
        "z": 14
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": -14
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": -7
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": 0
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": 7
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": -3.5,
        "z": 14
    },
    {
        "x": 10.5,
        "y": 0,
        "z": -14
    },
    {
        "x": 10.5,
        "y": 0,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": 0,
        "z": -7
    },
    {
        "x": 10.5,
        "y": 0,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": 0,
        "z": 0
    },
    {
        "x": 10.5,
        "y": 0,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": 0,
        "z": 7
    },
    {
        "x": 10.5,
        "y": 0,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": 0,
        "z": 14
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": -14
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": -7
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": 0
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": 7
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": 3.5,
        "z": 14
    },
    {
        "x": 10.5,
        "y": 7,
        "z": -14
    },
    {
        "x": 10.5,
        "y": 7,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": 7,
        "z": -7
    },
    {
        "x": 10.5,
        "y": 7,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": 7,
        "z": 0
    },
    {
        "x": 10.5,
        "y": 7,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": 7,
        "z": 7
    },
    {
        "x": 10.5,
        "y": 7,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": 7,
        "z": 14
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": -14
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": -7
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": 0
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": 7
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": 10.5,
        "z": 14
    },
    {
        "x": 10.5,
        "y": 14,
        "z": -14
    },
    {
        "x": 10.5,
        "y": 14,
        "z": -10.5
    },
    {
        "x": 10.5,
        "y": 14,
        "z": -7
    },
    {
        "x": 10.5,
        "y": 14,
        "z": -3.5
    },
    {
        "x": 10.5,
        "y": 14,
        "z": 0
    },
    {
        "x": 10.5,
        "y": 14,
        "z": 3.5
    },
    {
        "x": 10.5,
        "y": 14,
        "z": 7
    },
    {
        "x": 10.5,
        "y": 14,
        "z": 10.5
    },
    {
        "x": 10.5,
        "y": 14,
        "z": 14
    },
    {
        "x": 14,
        "y": -14,
        "z": -14
    },
    {
        "x": 14,
        "y": -14,
        "z": -10.5
    },
    {
        "x": 14,
        "y": -14,
        "z": -7
    },
    {
        "x": 14,
        "y": -14,
        "z": -3.5
    },
    {
        "x": 14,
        "y": -14,
        "z": 0
    },
    {
        "x": 14,
        "y": -14,
        "z": 3.5
    },
    {
        "x": 14,
        "y": -14,
        "z": 7
    },
    {
        "x": 14,
        "y": -14,
        "z": 10.5
    },
    {
        "x": 14,
        "y": -14,
        "z": 14
    },
    {
        "x": 14,
        "y": -10.5,
        "z": -14
    },
    {
        "x": 14,
        "y": -10.5,
        "z": -10.5
    },
    {
        "x": 14,
        "y": -10.5,
        "z": -7
    },
    {
        "x": 14,
        "y": -10.5,
        "z": -3.5
    },
    {
        "x": 14,
        "y": -10.5,
        "z": 0
    },
    {
        "x": 14,
        "y": -10.5,
        "z": 3.5
    },
    {
        "x": 14,
        "y": -10.5,
        "z": 7
    },
    {
        "x": 14,
        "y": -10.5,
        "z": 10.5
    },
    {
        "x": 14,
        "y": -10.5,
        "z": 14
    },
    {
        "x": 14,
        "y": -7,
        "z": -14
    },
    {
        "x": 14,
        "y": -7,
        "z": -10.5
    },
    {
        "x": 14,
        "y": -7,
        "z": -7
    },
    {
        "x": 14,
        "y": -7,
        "z": -3.5
    },
    {
        "x": 14,
        "y": -7,
        "z": 0
    },
    {
        "x": 14,
        "y": -7,
        "z": 3.5
    },
    {
        "x": 14,
        "y": -7,
        "z": 7
    },
    {
        "x": 14,
        "y": -7,
        "z": 10.5
    },
    {
        "x": 14,
        "y": -7,
        "z": 14
    },
    {
        "x": 14,
        "y": -3.5,
        "z": -14
    },
    {
        "x": 14,
        "y": -3.5,
        "z": -10.5
    },
    {
        "x": 14,
        "y": -3.5,
        "z": -7
    },
    {
        "x": 14,
        "y": -3.5,
        "z": -3.5
    },
    {
        "x": 14,
        "y": -3.5,
        "z": 0
    },
    {
        "x": 14,
        "y": -3.5,
        "z": 3.5
    },
    {
        "x": 14,
        "y": -3.5,
        "z": 7
    },
    {
        "x": 14,
        "y": -3.5,
        "z": 10.5
    },
    {
        "x": 14,
        "y": -3.5,
        "z": 14
    },
    {
        "x": 14,
        "y": 0,
        "z": -14
    },
    {
        "x": 14,
        "y": 0,
        "z": -10.5
    },
    {
        "x": 14,
        "y": 0,
        "z": -7
    },
    {
        "x": 14,
        "y": 0,
        "z": -3.5
    },
    {
        "x": 14,
        "y": 0,
        "z": 0
    },
    {
        "x": 14,
        "y": 0,
        "z": 3.5
    },
    {
        "x": 14,
        "y": 0,
        "z": 7
    },
    {
        "x": 14,
        "y": 0,
        "z": 10.5
    },
    {
        "x": 14,
        "y": 0,
        "z": 14
    },
    {
        "x": 14,
        "y": 3.5,
        "z": -14
    },
    {
        "x": 14,
        "y": 3.5,
        "z": -10.5
    },
    {
        "x": 14,
        "y": 3.5,
        "z": -7
    },
    {
        "x": 14,
        "y": 3.5,
        "z": -3.5
    },
    {
        "x": 14,
        "y": 3.5,
        "z": 0
    },
    {
        "x": 14,
        "y": 3.5,
        "z": 3.5
    },
    {
        "x": 14,
        "y": 3.5,
        "z": 7
    },
    {
        "x": 14,
        "y": 3.5,
        "z": 10.5
    },
    {
        "x": 14,
        "y": 3.5,
        "z": 14
    },
    {
        "x": 14,
        "y": 7,
        "z": -14
    },
    {
        "x": 14,
        "y": 7,
        "z": -10.5
    },
    {
        "x": 14,
        "y": 7,
        "z": -7
    },
    {
        "x": 14,
        "y": 7,
        "z": -3.5
    },
    {
        "x": 14,
        "y": 7,
        "z": 0
    },
    {
        "x": 14,
        "y": 7,
        "z": 3.5
    },
    {
        "x": 14,
        "y": 7,
        "z": 7
    },
    {
        "x": 14,
        "y": 7,
        "z": 10.5
    },
    {
        "x": 14,
        "y": 7,
        "z": 14
    },
    {
        "x": 14,
        "y": 10.5,
        "z": -14
    },
    {
        "x": 14,
        "y": 10.5,
        "z": -10.5
    },
    {
        "x": 14,
        "y": 10.5,
        "z": -7
    },
    {
        "x": 14,
        "y": 10.5,
        "z": -3.5
    },
    {
        "x": 14,
        "y": 10.5,
        "z": 0
    },
    {
        "x": 14,
        "y": 10.5,
        "z": 3.5
    },
    {
        "x": 14,
        "y": 10.5,
        "z": 7
    },
    {
        "x": 14,
        "y": 10.5,
        "z": 10.5
    },
    {
        "x": 14,
        "y": 10.5,
        "z": 14
    },
    {
        "x": 14,
        "y": 14,
        "z": -14
    },
    {
        "x": 14,
        "y": 14,
        "z": -10.5
    },
    {
        "x": 14,
        "y": 14,
        "z": -7
    },
    {
        "x": 14,
        "y": 14,
        "z": -3.5
    },
    {
        "x": 14,
        "y": 14,
        "z": 0
    },
    {
        "x": 14,
        "y": 14,
        "z": 3.5
    },
    {
        "x": 14,
        "y": 14,
        "z": 7
    },
    {
        "x": 14,
        "y": 14,
        "z": 10.5
    },
    {
        "x": 14,
        "y": 14,
        "z": 14
    }
]