import './style.scss'
import * as THREE from 'three'
import gsap from 'gsap'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import vertexShaderInnerSphere from './shaders/innerSphere/vertex.glsl'
import fragmentShaderInnerSphere from './shaders/innerSphere/fragment.glsl'
import vertexShaderOuterSphere from './shaders/outerSphere/vertex.glsl'
import fragmentShaderOuterSphere from './shaders/outerSphere/fragment.glsl'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js'
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js'
import * as dat from 'lil-gui'
import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";

import { spherearray } from '../static/array.js'
import { arraycube } from '../static/arraycube.js'

gsap.registerPlugin(ExpoScaleEase, RoughEase, SlowMo);

/**
 * Base
 */
// Debug

// const gui = new dat.GUI({ width: 340})
const params = {
    normalBallColor: new THREE.Color('#dbdbdb'),
    distance: 0.5
}

/**
 * Update Materials
 */
const updateAllMaterials = () => {
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial) {
            child.material.needsUpdate = true
        }
    })
}


// Canvas
const canvas = document.querySelector('canvas.webgl')

/**
 * Loaders
 */
const textureLoader = new THREE.TextureLoader()
const objLoader = new OBJLoader()

// background texture
const backgroundtexture = textureLoader.load('textures/background.png')
backgroundtexture.magFilter = THREE.NearestFilter
backgroundtexture.minFilter = THREE.LinearMipmapNearestFilter 
// Scene
const scene = new THREE.Scene()

// scene.background = backgroundtexture




/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(40, sizes.width / sizes.height, 0.1, 400)
camera.position.set(0, -3, 60)
//camera.position.set(-40, -3, 55)

// gui.add(camera.position, 'x').min(-100).max(100).step(0.001).name('cameraXposition')
// gui.add(camera.position, 'y').min(-100).max(100).step(0.001).name('cameraYposition')
// gui.add(camera.position, 'z').min(-100).max(100).step(0.001).name('cameraZposition')

scene.add(camera)



/**
 * 
 * 
 * 
 * 
 * 
 * Palle shaders
 * 
 * 
 * 
 * 
 */


// Geometries
const sphereGeometry = new THREE.SphereGeometry(0.7, 8, 5)
const matcap = textureLoader.load('textures/matcap3.jpg')
// Materials
let innerSphereMaterial = new THREE.MeshMatcapMaterial({
    matcap: matcap,
    color: params.normalBallColor
})


params.atmosphereColor = {
    value: new THREE.Color('#ffffff')
}

// outerSphere
const outerSphereMaterial = new THREE.ShaderMaterial({
    vertexShader: vertexShaderOuterSphere,
    fragmentShader: fragmentShaderOuterSphere,
    alphaTest: false,
    transparent: true,
    blending: THREE.AdditiveBlending,
    side: THREE.BackSide,
    uniforms: { 
        uColor: {value: new THREE.Color(params.atmosphereColor)}
    }
})

// gui.addColor(params.atmosphereColor, 'value').name('atmosphere color')

params.pallesempliciColor = {
    value: new THREE.Color('#ffffff')
}

const innerSphereAtmosphereMaterial = new THREE.ShaderMaterial({
    vertexShader: vertexShaderInnerSphere,
    fragmentShader: fragmentShaderInnerSphere,
    wireframe: true,
    uniforms: {
        uColor: { value: new THREE.Color(params.pallesempliciColor.value) },
        uIntensity: { value: 1 },
        uInnerColor: { value: new THREE.Color('#081921') }
    }
})

// gui.addColor(params.pallesempliciColor, 'value').name('palle luminose')



const cubeAnimationVertex = []
const numberBall = 4
for(let i = - numberBall; i <= numberBall; i++) {
    
        for(let y = -numberBall; y <= numberBall; y++) {
            
                for(let z = -numberBall; z <= numberBall; z++){
                    

                        // save vertices for later animations
                        cubeAnimationVertex.push({x: i * 3.5, y: y * 3.5, z: z * 3.5});
                        
                        // group
                        const composedSphere = new THREE.Group()
                        
                        const scale = Math.random()
                        let scale2
                        if(Math.random() < 0.1) {
                             scale2 = 0.5
                        }else{
                             scale2 = Math.random() * 0.001
                        }

                        // innerSphere
                        const sphere = new THREE.Mesh(sphereGeometry, innerSphereMaterial)
                        
                        // sphere.material.opacity = 0.8
                        sphere.castShadow = true
                        composedSphere.add(sphere)


                        const atmosphere = new THREE.Mesh(sphereGeometry, outerSphereMaterial)
                        atmosphere.visible = false
                        composedSphere.add(atmosphere)
                        

                        if(Math.random() < 0.06){
                            sphere.scale.set(0.7, 0.7, 0.7)
                            atmosphere.scale.set(1, 1, 1)
                            atmosphere.visible = true
                            sphere.material = innerSphereAtmosphereMaterial
                        }else{
                            sphere.geometry = new THREE.SphereBufferGeometry(0.7, 20, 20)
                            sphere.scale.set(Math.min(scale2 + scale * 0.8, 0.5), Math.min(scale2 + scale * 0.8, 0.5), Math.min(scale2 + scale * 0.8, 0.5))
                        
                        }

                        composedSphere.position.set(i * 3.5, y * 3.5, z * 3.5)
                        // composedSphere.position.set(arrObjTwo, arrObjTwo, arrObjTwo)
                        // sectionMeshes.push(composedSphere)
                        scene.add(composedSphere)
                    
                }
            
        }
    
}


/***
 * Animation Class
 */
    scene.traverse(child => {
        if (child.type == 'Group') {
        

            const fluctuate = () => {
                const randomX = Math.random() * params.distance - params.distance * 0.5
                const randomY = Math.random() * params.distance - params.distance * 0.5
                const randomZ = Math.random() * params.distance - params.distance * 0.5
                if (Math.random() < 0.01) {
                    gsap.to(child.position, {
                        x: `+=${randomX * 10}`,
                        y: `+=${randomY * 10}`,
                        z: `+=${randomZ * 10}`,
                        ease: 'linear',
                        repeat: 1,
                        yoyo: true,
                        duration: 0.1 + Math.random() * 0.1,
                        onComplete: fluctuate
                    })
                } else {
                    gsap.to(child.position, {
                        x: `+=${randomX}`,
                        y: `+=${randomY}`,
                        z: `+=${randomZ}`,
                        ease: 'linear',
                        repeat: 1,
                        yoyo: true,
                        duration: 0.1 + Math.random() * 0.1,
                        onComplete: fluctuate
                    })
                }
            }


            const delayReveal = 1 + (Math.abs(child.position.x) + Math.abs(child.position.y) + Math.abs(child.position.z)) * 0.02
            gsap.from(child.scale, {
                x: 0,
                y: 0,
                z: 0,
                duration: 1,
                delay: delayReveal
            })

            gsap.from(child.position, {
                x: 0,
                y: 0,
                z: 0,
                duration: 1,
                delay: delayReveal,
                onComplete: fluctuate
            })
        }
    })



const button1 = async(array) => {
    // console.log('bella')
    let count = 0
    scene.traverse(child => {
        if (child.type == 'Group') {

            const transformToDonuts = () => {
                // transformation
                if (array[count] != undefined) {
                    child.children[0].visible = true
                    child.children.forEach(mesh => {
                        
                        gsap.to(mesh.position, {
                            // z: Math.cos((mesh.position.y) * Math.PI / 13.5 * 0.5) * Math.cos((mesh.position.x) * Math.PI / 13.5 * 0.5) * 3 * Math.PI * mesh.position.z / 13.5 + mesh.position.z / Math.PI -10.9,
                            // x: Math.cos((mesh.position.z) * Math.PI / 13.5 * 0.5) * Math.cos((mesh.position.y) * Math.PI / 13.5 * 0.5) * 3 * Math.PI * mesh.position.x / 13.5 + mesh.position.x / Math.PI -20.9,
                            // y: Math.cos((mesh.position.x) * Math.PI / 13.5 * 0.5) * Math.cos((mesh.position.z) * Math.PI / 13.5 * 0.5) * 3 * Math.PI * mesh.position.y / 13.5 + mesh.position.y / Math.PI -0.9,
                            x: array[count].x - child.position.x,
                            y: array[count].y - child.position.y,
                            z: array[count].z - child.position.z,
                            ease: 'easeIn',
                            duration: 0.6,
                            delay: Math.abs(child.position.z * 0.05) + Math.abs(child.position.y * 0.008) + Math.abs(child.position.y * 0.005),
                        })
                    })
                }else{
                    child.children.forEach(mesh => {
                        gsap.to(mesh.position, {
                            x: 0 - child.position.x,
                            y: 0 - child.position.y,
                            z: 0 - child.position.z,
                            ease: 'easeIn',
                            duration: 0.6,
                            delay: Math.abs(child.position.z * 0.05) + Math.abs(child.position.y * 0.008) + Math.abs(child.position.y * 0.005),
                            onComplete: ()=> {
                                mesh.visible = false
                            }
                        })
                    })
                }
            }
            transformToDonuts()
            count++
        }
    })
}

   




/**
 * Particles
 */
// Geometry
// const particlesGeometry = new THREE.BufferGeometry()
const particlesGeometry1 = new THREE.BufferGeometry()
const count = 800


// const positions = new Float32Array(count * 3) // Multiply by 3 because each position is composed of 3 values (x, y, z)
const positions1 = new Float32Array(count * 3) // Multiply by 3 because each position is composed of 3 values (x, y, z)

for (let i = 0; i < count * 3; i++) // Multiply by 3 for same reason
{
    // positions[i] = (Math.random() - 0.5) * 200 // Math.random() - 0.5 to have a random value between -0.5 and +0.5
    positions1[i] = (Math.random() - 0.5) * 200 // Math.random() - 0.5 to have a random value between -0.5 and +0.5
}

// particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3)) // Create the Three.js BufferAttribute and specify that each information is composed of 3 values
particlesGeometry1.setAttribute('position', new THREE.BufferAttribute(positions1, 3)) // Create the Three.js BufferAttribute and specify that each information is composed of 3 values

// Material

// const particlesMaterial = new THREE.PointsMaterial()
// particlesMaterial.map = textureLoader.load('textures/particle.svg')
// particlesMaterial.alphaMap = textureLoader.load('textures/particle.svg')
// particlesMaterial.transparent = true
// particlesMaterial.size = 1
// particlesMaterial.color = new THREE.Color('#bebebe')
// particlesMaterial.blending = THREE.AdditiveBlending
// particlesMaterial.depthTest = false
// particlesMaterial.sizeAttenuation = true


const particlesMaterial1 = new THREE.PointsMaterial()
particlesMaterial1.size = 0.5
particlesMaterial1.color = new THREE.Color('#bebebe')
particlesMaterial1.sizeAttenuation = true

// gui.addColor(particlesMaterial, 'color').name('particelle')

// Points
// const particles = new THREE.Points(particlesGeometry, particlesMaterial)
const particles1 = new THREE.Points(particlesGeometry1, particlesMaterial1)
scene.add(particles1)


/**
 * Fog
 */
const fog = new THREE.Fog('#000000', 75, 140)
scene.fog = fog








window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})



var x = window.matchMedia("(max-width: 991px)")


// Controls
const controls = new OrbitControls(camera, canvas)
// controls.target = new THREE.Vector3(-300, -5, 0)
controls.enableDamping = true
controls.enablePan = false
controls.enableZoom = false
if(x.matches){
    controls.autoRotate = true
}

// scene 1

button1(spherearray)

    
gsap.set(controls.target, {
    x: 0
})

function myFunction(x) {
    if (x.matches) { // If media query matches
        // mobile
        gsap.to(controls.target, {
            x: 0,
            y: 0,
            duration: 1.5,
            ease: 'power3.inOut',
            delay: 2
        })
    } else {
        gsap.fromTo(controls.target, {
            x: 20
        }, {
            x: -20,
            duration: 1.5,
            ease: 'power3.inOut',
            delay: 2
        })
    }
}

myFunction(x)



if(x.matches){

    gsap.from(camera.position, {
        z: - 80,
        duration: 3,
        ease: 'power3.inOut',
        delay: 1,
        onComplete: function () {
            document.querySelectorAll('.hiddentext').forEach(t => {
                t.classList.add('revealtext')
            })
        }
    })

}else{

    gsap.from(camera.position, {
        z: - 80,
        duration: 3,
        ease: 'power3.inOut',
        delay: 1.5,
        onComplete: function(){
            document.querySelectorAll('.hiddentext').forEach(t => {
                t.classList.add('revealtext')
            })
        }
    })
}

function myFunction2(x) {
    if (x.matches) {
        gsap.to(camera, {
            duration: 1,
            zoom: 0.4,
            onUpdate: function () {

                camera.updateProjectionMatrix();

            },
            ease: 'power3.inOut',
            delay: 0.5,
        });
    } else {
        gsap.to(camera, {
            duration: 1,
            zoom: 0.7,
            onUpdate: function () {

                camera.updateProjectionMatrix();

            },
            ease: 'power3.inOut',
            delay: 0.5,
        });
    }
}

myFunction2(x)

// parallax mouse
const mouseposition = {
    x: 0,
    y: 0
}



window.addEventListener('mousemove', (e)=>{
    mouseposition.x = e.clientX / window.innerWidth - 0.5;
    mouseposition.y = -(e.clientY / window.innerHeight - 0.5);
})



/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setClearColor(new THREE.Color('#0e0e0e'))
renderer.antialias = true
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.shadowMap.enabled = false
renderer.outputEncoding = THREE.sRGBEncoding


// params.backgroundColor = new THREE.Color('#000000')

// gui.addColor(params, 'backgroundColor')

// renderer.toneMapping = THREE.ACESFilmicToneMapping
// renderer.toneMappingExposure = 1.19
// gui
//     .add(renderer, 'toneMapping', {
//         No: THREE.NoToneMapping,
//         Linear: THREE.LinearToneMapping,
//         Reinhard: THREE.ReinhardToneMapping,
//         Cineon: THREE.CineonToneMapping,
//         ACESFilmic: THREE.ACESFilmicToneMapping
//     })
//     .onFinishChange(() => {
//         renderer.toneMapping = Number(renderer.toneMapping)
//         updateAllMaterials()
//     })

// gui.add(renderer, 'toneMappingExposure').min(0).max(10).step(0.001)

/**
 * Animate
 */


// post processing

const effectComposer = new EffectComposer(renderer)
effectComposer.setSize(sizes.width, sizes.height)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)

let bokehPass

if(x.matches){
     bokehPass = new BokehPass(scene, camera, {
        focus: 60,
        aperture: 0.00015,
        maxblur: 0.004
    })

}else{
     bokehPass = new BokehPass(scene, camera, {
        focus: 40,
        aperture: 0.00015,
        maxblur: 0.004
    })
}
    

effectComposer.addPass(bokehPass)


let currentShape = 'sphere'
let currentInt = 10


const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    // particlesMaterial.uniforms.uTime.value = elapsedTime * 1.3
    // particlesMaterial.uniforms.uIntensity.value = params.uIntensity
    // particlesMaterial.uniforms.uColor.value = params.uBackColor
    innerSphereAtmosphereMaterial.uniforms.uColor.value = params.pallesempliciColor.value

    outerSphereMaterial.uniforms.uColor.value = params.atmosphereColor.value

    // switch cube sphere
    if(elapsedTime <= currentInt){
    
    }else{
        if(currentShape === 'sphere'){
            button1(arraycube)
            currentShape = 'cube'
        }else{
            button1(spherearray)
            currentShape = 'sphere'
        }
        currentInt += 7
    }

    if(!x.matches){
        let speed = - mouseposition.x * 0.01
        controls.target.x = controls.target.x * Math.cos(speed) + controls.target.z * Math.sin(speed);
        controls.target.z = controls.target.z * Math.cos(speed) - controls.target.x * Math.sin(speed);
        camera.position.x = camera.position.x * Math.cos(speed) + camera.position.z * Math.sin(speed);
        camera.position.z = camera.position.z * Math.cos(speed) - camera.position.x * Math.sin(speed);
        // camera.position.x = mouseposition.x * 5
        camera.position.y = - mouseposition.y * 5
    }

    // Update controls
    controls.update()

    /// particles.rotation.y = elapsedTime * 0.02

    // Render
    // renderer.setClearColor(params.backgroundColor)
    // renderer.render(scene, camera)
    effectComposer.render()

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()