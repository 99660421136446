export const spherearray = [
    
        {
            "x": 0,
            "y": 10.806046485900879,
            "z": 16.82942008972168
        },
        {
            "x": -3.1286892890930176,
            "y": 10.673006057739258,
            "z": 16.622220993041992
        },
        {
            "x": -2.975560426712036,
            "y": 9.859456062316895,
            "z": 17.144596099853516
        },
        {
            "x": -2.531162738800049,
            "y": 9.125542640686035,
            "z": 17.61583709716797
        },
        {
            "x": -1.838997483253479,
            "y": 8.543106079101562,
            "z": 17.98981475830078
        },
        {
            "x": -0.9668181538581848,
            "y": 8.169157981872559,
            "z": 18.229923248291016
        },
        {
            "x": -1.9157696216119213e-16,
            "y": 8.040304183959961,
            "z": 18.312660217285156
        },
        {
            "x": 0.9668181538581848,
            "y": 8.169157981872559,
            "z": 18.229923248291016
        },
        {
            "x": 1.838997483253479,
            "y": 8.543106079101562,
            "z": 17.98981475830078
        },
        {
            "x": 2.531162738800049,
            "y": 9.125542640686035,
            "z": 17.61583709716797
        },
        {
            "x": 2.975560426712036,
            "y": 9.859456062316895,
            "z": 17.144596099853516
        },
        {
            "x": 3.1286892890930176,
            "y": 10.673006057739258,
            "z": 16.622220993041992
        },
        {
            "x": 2.975560426712036,
            "y": 11.486555099487305,
            "z": 16.0998477935791
        },
        {
            "x": 2.531162738800049,
            "y": 12.220468521118164,
            "z": 15.628606796264648
        },
        {
            "x": 1.838997483253479,
            "y": 12.802906036376953,
            "z": 15.254629135131836
        },
        {
            "x": 0.9668181538581848,
            "y": 13.17685317993164,
            "z": 15.014519691467285
        },
        {
            "x": 5.74730912953356e-16,
            "y": 13.305706977844238,
            "z": 14.931783676147461
        },
        {
            "x": -0.9668181538581848,
            "y": 13.17685317993164,
            "z": 15.014519691467285
        },
        {
            "x": -1.838997483253479,
            "y": 12.802906036376953,
            "z": 15.254629135131836
        },
        {
            "x": -2.531162738800049,
            "y": 12.220468521118164,
            "z": 15.628606796264648
        },
        {
            "x": -2.975560426712036,
            "y": 11.486555099487305,
            "z": 16.0998477935791
        },
        {
            "x": -6.180339813232422,
            "y": 10.27716064453125,
            "z": 16.005727767944336
        },
        {
            "x": -5.877852439880371,
            "y": 8.670093536376953,
            "z": 17.037614822387695
        },
        {
            "x": -5,
            "y": 7.220337867736816,
            "z": 17.96849250793457
        },
        {
            "x": -3.6327126026153564,
            "y": 6.069805145263672,
            "z": 18.70724105834961
        },
        {
            "x": -1.909830093383789,
            "y": 5.331118106842041,
            "z": 19.18154525756836
        },
        {
            "x": -3.7843666549022706e-16,
            "y": 5.076583385467529,
            "z": 19.344980239868164
        },
        {
            "x": 1.909830093383789,
            "y": 5.331118106842041,
            "z": 19.18154525756836
        },
        {
            "x": 3.6327126026153564,
            "y": 6.069805145263672,
            "z": 18.70724105834961
        },
        {
            "x": 5,
            "y": 7.220337867736816,
            "z": 17.96849250793457
        },
        {
            "x": 5.877852439880371,
            "y": 8.670093536376953,
            "z": 17.037614822387695
        },
        {
            "x": 6.180339813232422,
            "y": 10.27716064453125,
            "z": 16.005727767944336
        },
        {
            "x": 5.877852439880371,
            "y": 11.88422679901123,
            "z": 14.97384262084961
        },
        {
            "x": 5,
            "y": 13.333982467651367,
            "z": 14.04296588897705
        },
        {
            "x": 3.6327126026153564,
            "y": 14.484515190124512,
            "z": 13.304217338562012
        },
        {
            "x": 1.909830093383789,
            "y": 15.2232027053833,
            "z": 12.829911231994629
        },
        {
            "x": 1.1353100229404608e-15,
            "y": 15.477736473083496,
            "z": 12.66647720336914
        },
        {
            "x": -1.909830093383789,
            "y": 15.2232027053833,
            "z": 12.829911231994629
        },
        {
            "x": -3.6327126026153564,
            "y": 14.484515190124512,
            "z": 13.304217338562012
        },
        {
            "x": -5,
            "y": 13.333982467651367,
            "z": 14.04296588897705
        },
        {
            "x": -5.877852439880371,
            "y": 11.88422679901123,
            "z": 14.97384262084961
        },
        {
            "x": -9.07981014251709,
            "y": 9.628257751464844,
            "z": 14.995123863220215
        },
        {
            "x": -8.635412216186523,
            "y": 7.267245769500732,
            "z": 16.511112213134766
        },
        {
            "x": -7.3457207679748535,
            "y": 5.137345314025879,
            "z": 17.878705978393555
        },
        {
            "x": -5.336978435516357,
            "y": 3.447047233581543,
            "z": 18.964033126831055
        },
        {
            "x": -2.8058156967163086,
            "y": 2.361809253692627,
            "z": 19.660856246948242
        },
        {
            "x": -5.559780270176194e-16,
            "y": 1.9878612756729126,
            "z": 19.90096664428711
        },
        {
            "x": 2.8058156967163086,
            "y": 2.361809253692627,
            "z": 19.660856246948242
        },
        {
            "x": 5.336978435516357,
            "y": 3.447047233581543,
            "z": 18.964033126831055
        },
        {
            "x": 7.3457207679748535,
            "y": 5.137345314025879,
            "z": 17.878705978393555
        },
        {
            "x": 8.635412216186523,
            "y": 7.267245769500732,
            "z": 16.511112213134766
        },
        {
            "x": 9.07981014251709,
            "y": 9.628257751464844,
            "z": 14.995123863220215
        },
        {
            "x": 8.635412216186523,
            "y": 11.989270210266113,
            "z": 13.479134559631348
        },
        {
            "x": 7.3457207679748535,
            "y": 14.119170188903809,
            "z": 12.111541748046875
        },
        {
            "x": 5.336978435516357,
            "y": 15.809469223022461,
            "z": 11.026213645935059
        },
        {
            "x": 2.8058156967163086,
            "y": 16.89470672607422,
            "z": 10.329390525817871
        },
        {
            "x": 1.6679340810528583e-15,
            "y": 17.268653869628906,
            "z": 10.08928108215332
        },
        {
            "x": -2.8058156967163086,
            "y": 16.89470672607422,
            "z": 10.329390525817871
        },
        {
            "x": -5.336978435516357,
            "y": 15.809469223022461,
            "z": 11.026213645935059
        },
        {
            "x": -7.3457207679748535,
            "y": 14.119170188903809,
            "z": 12.111541748046875
        },
        {
            "x": -8.635412216186523,
            "y": 11.989270210266113,
            "z": 13.479134559631348
        },
        {
            "x": -11.755704879760742,
            "y": 8.74227523803711,
            "z": 13.615286827087402
        },
        {
            "x": -11.180339813232422,
            "y": 5.685452461242676,
            "z": 15.578049659729004
        },
        {
            "x": -9.510564804077148,
            "y": 2.9278533458709717,
            "z": 17.348684310913086
        },
        {
            "x": -6.909830093383789,
            "y": 0.7394105792045593,
            "z": 18.75386619567871
        },
        {
            "x": -3.6327126026153564,
            "y": -0.6656566262245178,
            "z": 19.656049728393555
        },
        {
            "x": -7.198293508839122e-16,
            "y": -1.1498095989227295,
            "z": 19.966920852661133
        },
        {
            "x": 3.6327126026153564,
            "y": -0.6656566262245178,
            "z": 19.656049728393555
        },
        {
            "x": 6.909830093383789,
            "y": 0.7394105792045593,
            "z": 18.75386619567871
        },
        {
            "x": 9.510564804077148,
            "y": 2.9278533458709717,
            "z": 17.348684310913086
        },
        {
            "x": 11.180339813232422,
            "y": 5.685452461242676,
            "z": 15.578049659729004
        },
        {
            "x": 11.755704879760742,
            "y": 8.74227523803711,
            "z": 13.615286827087402
        },
        {
            "x": 11.180339813232422,
            "y": 11.799097061157227,
            "z": 11.652523040771484
        },
        {
            "x": 9.510564804077148,
            "y": 14.556696891784668,
            "z": 9.881889343261719
        },
        {
            "x": 6.909830093383789,
            "y": 16.745140075683594,
            "z": 8.476706504821777
        },
        {
            "x": 3.6327126026153564,
            "y": 18.150205612182617,
            "z": 7.574522972106934
        },
        {
            "x": 2.1594880526517365e-15,
            "y": 18.63435935974121,
            "z": 7.2636518478393555
        },
        {
            "x": -3.6327126026153564,
            "y": 18.150205612182617,
            "z": 7.574522972106934
        },
        {
            "x": -6.909830093383789,
            "y": 16.745140075683594,
            "z": 8.476706504821777
        },
        {
            "x": -9.510564804077148,
            "y": 14.556696891784668,
            "z": 9.881889343261719
        },
        {
            "x": -11.180339813232422,
            "y": 11.799097061157227,
            "z": 11.652523040771484
        },
        {
            "x": -14.142135620117188,
            "y": 7.64102840423584,
            "z": 11.90019702911377
        },
        {
            "x": -13.449970245361328,
            "y": 3.96366548538208,
            "z": 14.261404037475586
        },
        {
            "x": -11.441227912902832,
            "y": 0.6462680101394653,
            "z": 16.391481399536133
        },
        {
            "x": -8.312539100646973,
            "y": -1.9864327907562256,
            "z": 18.081918716430664
        },
        {
            "x": -4.370160102844238,
            "y": -3.6767311096191406,
            "z": 19.167245864868164
        },
        {
            "x": -8.659560338728758e-16,
            "y": -4.2591681480407715,
            "z": 19.54122543334961
        },
        {
            "x": 4.370160102844238,
            "y": -3.6767311096191406,
            "z": 19.167245864868164
        },
        {
            "x": 8.312539100646973,
            "y": -1.9864327907562256,
            "z": 18.081918716430664
        },
        {
            "x": 11.441227912902832,
            "y": 0.6462680101394653,
            "z": 16.391481399536133
        },
        {
            "x": 13.449970245361328,
            "y": 3.96366548538208,
            "z": 14.261404037475586
        },
        {
            "x": 14.142135620117188,
            "y": 7.64102840423584,
            "z": 11.90019702911377
        },
        {
            "x": 13.449970245361328,
            "y": 11.318391799926758,
            "z": 9.538989067077637
        },
        {
            "x": 11.441227912902832,
            "y": 14.635788917541504,
            "z": 7.408912658691406
        },
        {
            "x": 8.312539100646973,
            "y": 17.268489837646484,
            "z": 5.718474864959717
        },
        {
            "x": 4.370160102844238,
            "y": 18.95878791809082,
            "z": 4.6331467628479
        },
        {
            "x": 2.597868260437305e-15,
            "y": 19.54122543334961,
            "z": 4.2591681480407715
        },
        {
            "x": -4.370160102844238,
            "y": 18.95878791809082,
            "z": 4.6331467628479
        },
        {
            "x": -8.312539100646973,
            "y": 17.268489837646484,
            "z": 5.718474864959717
        },
        {
            "x": -11.441227912902832,
            "y": 14.635788917541504,
            "z": 7.408912658691406
        },
        {
            "x": -13.449970245361328,
            "y": 11.318391799926758,
            "z": 9.538989067077637
        },
        {
            "x": -16.180339813232422,
            "y": 6.351634502410889,
            "z": 9.892084121704102
        },
        {
            "x": -15.38841724395752,
            "y": 2.1442794799804688,
            "z": 12.593596458435059
        },
        {
            "x": -13.090169906616211,
            "y": -1.6512298583984375,
            "z": 15.030664443969727
        },
        {
            "x": -9.510564804077148,
            "y": -4.663363933563232,
            "z": 16.964733123779297
        },
        {
            "x": -5,
            "y": -6.5972723960876465,
            "z": 18.20648193359375
        },
        {
            "x": -9.907600388432518e-16,
            "y": -7.2636518478393555,
            "z": 18.63435935974121
        },
        {
            "x": 5,
            "y": -6.5972723960876465,
            "z": 18.20648193359375
        },
        {
            "x": 9.510564804077148,
            "y": -4.663363933563232,
            "z": 16.964733123779297
        },
        {
            "x": 13.090169906616211,
            "y": -1.6512298583984375,
            "z": 15.030664443969727
        },
        {
            "x": 15.38841724395752,
            "y": 2.1442794799804688,
            "z": 12.593596458435059
        },
        {
            "x": 16.180339813232422,
            "y": 6.351634502410889,
            "z": 9.892084121704102
        },
        {
            "x": 15.38841724395752,
            "y": 10.558989524841309,
            "z": 7.190573215484619
        },
        {
            "x": 13.090169906616211,
            "y": 14.354498863220215,
            "z": 4.753504276275635
        },
        {
            "x": 9.510564804077148,
            "y": 17.36663246154785,
            "z": 2.8194355964660645
        },
        {
            "x": 5,
            "y": 19.300540924072266,
            "z": 1.5776872634887695
        },
        {
            "x": 2.9722801165297554e-15,
            "y": 19.966920852661133,
            "z": 1.1498095989227295
        },
        {
            "x": -5,
            "y": 19.300540924072266,
            "z": 1.5776872634887695
        },
        {
            "x": -9.510564804077148,
            "y": 17.36663246154785,
            "z": 2.8194355964660645
        },
        {
            "x": -13.090169906616211,
            "y": 14.354498863220215,
            "z": 4.753504276275635
        },
        {
            "x": -15.38841724395752,
            "y": 10.558989524841309,
            "z": 7.190573215484619
        },
        {
            "x": -17.820131301879883,
            "y": 4.905842304229736,
            "z": 7.640396595001221
        },
        {
            "x": -16.94795036315918,
            "y": 0.2720947861671448,
            "z": 10.615692138671875
        },
        {
            "x": -14.416788101196289,
            "y": -3.9080698490142822,
            "z": 13.299744606018066
        },
        {
            "x": -10.474410057067871,
            "y": -7.225466728210449,
            "z": 15.429821014404297
        },
        {
            "x": -5.506722927093506,
            "y": -9.355365753173828,
            "z": 16.797412872314453
        },
        {
            "x": -1.0911682773228422e-15,
            "y": -10.08928108215332,
            "z": 17.268653869628906
        },
        {
            "x": 5.506722927093506,
            "y": -9.355365753173828,
            "z": 16.797412872314453
        },
        {
            "x": 10.474410057067871,
            "y": -7.225466728210449,
            "z": 15.429821014404297
        },
        {
            "x": 14.416788101196289,
            "y": -3.9080698490142822,
            "z": 13.299744606018066
        },
        {
            "x": 16.94795036315918,
            "y": 0.2720947861671448,
            "z": 10.615692138671875
        },
        {
            "x": 17.820131301879883,
            "y": 4.905842304229736,
            "z": 7.640396595001221
        },
        {
            "x": 16.94795036315918,
            "y": 9.539589881896973,
            "z": 4.665101528167725
        },
        {
            "x": 14.416788101196289,
            "y": 13.719754219055176,
            "z": 1.981048822402954
        },
        {
            "x": 10.474410057067871,
            "y": 17.037151336669922,
            "z": -0.14902706444263458
        },
        {
            "x": 5.506722927093506,
            "y": 19.167051315307617,
            "z": -1.5166199207305908
        },
        {
            "x": 3.273504937847645e-15,
            "y": 19.90096664428711,
            "z": -1.9878612756729126
        },
        {
            "x": -5.506722927093506,
            "y": 19.167051315307617,
            "z": -1.5166199207305908
        },
        {
            "x": -10.474410057067871,
            "y": 17.037151336669922,
            "z": -0.14902706444263458
        },
        {
            "x": -14.416788101196289,
            "y": 13.719754219055176,
            "z": 1.981048822402954
        },
        {
            "x": -16.94795036315918,
            "y": 9.539589881896973,
            "z": 4.665101528167725
        },
        {
            "x": -19.021129608154297,
            "y": 3.339251756668091,
            "z": 5.2005767822265625
        },
        {
            "x": -18.09016990661621,
            "y": -1.6067904233932495,
            "z": 8.376394271850586
        },
        {
            "x": -15.38841724395752,
            "y": -6.0686798095703125,
            "z": 11.241339683532715
        },
        {
            "x": -11.180339813232422,
            "y": -9.609654426574707,
            "z": 13.514973640441895
        },
        {
            "x": -5.877852439880371,
            "y": -11.883101463317871,
            "z": 14.974737167358398
        },
        {
            "x": -1.164708313078171e-15,
            "y": -12.66647720336914,
            "z": 15.477736473083496
        },
        {
            "x": 5.877852439880371,
            "y": -11.883101463317871,
            "z": 14.974737167358398
        },
        {
            "x": 11.180339813232422,
            "y": -9.609654426574707,
            "z": 13.514973640441895
        },
        {
            "x": 15.38841724395752,
            "y": -6.0686798095703125,
            "z": 11.241339683532715
        },
        {
            "x": 18.09016990661621,
            "y": -1.6067904233932495,
            "z": 8.376394271850586
        },
        {
            "x": 19.021129608154297,
            "y": 3.339251756668091,
            "z": 5.2005767822265625
        },
        {
            "x": 18.09016990661621,
            "y": 8.285294532775879,
            "z": 2.024759292602539
        },
        {
            "x": 15.38841724395752,
            "y": 12.747183799743652,
            "z": -0.8401867747306824
        },
        {
            "x": 11.180339813232422,
            "y": 16.288158416748047,
            "z": -3.113820791244507
        },
        {
            "x": 5.877852439880371,
            "y": 18.56160545349121,
            "z": -4.573584079742432
        },
        {
            "x": 3.4941250451136314e-15,
            "y": 19.344980239868164,
            "z": -5.076583385467529
        },
        {
            "x": -5.877852439880371,
            "y": 18.56160545349121,
            "z": -4.573584079742432
        },
        {
            "x": -11.180339813232422,
            "y": 16.288158416748047,
            "z": -3.113820791244507
        },
        {
            "x": -15.38841724395752,
            "y": 12.747183799743652,
            "z": -0.8401867747306824
        },
        {
            "x": -18.09016990661621,
            "y": 8.285294532775879,
            "z": 2.024759292602539
        },
        {
            "x": -19.753767013549805,
            "y": 1.6904380321502686,
            "z": 2.6327011585235596
        },
        {
            "x": -18.786949157714844,
            "y": -3.446110725402832,
            "z": 5.930841445922852
        },
        {
            "x": -15.981133460998535,
            "y": -8.079858779907227,
            "z": 8.906136512756348
        },
        {
            "x": -11.61097240447998,
            "y": -11.757222175598145,
            "z": 11.26734447479248
        },
        {
            "x": -6.104249477386475,
            "y": -14.118234634399414,
            "z": 12.783332824707031
        },
        {
            "x": -1.2095694014262453e-15,
            "y": -14.931783676147461,
            "z": 13.305706977844238
        },
        {
            "x": 6.104249477386475,
            "y": -14.118234634399414,
            "z": 12.783332824707031
        },
        {
            "x": 11.61097240447998,
            "y": -11.757222175598145,
            "z": 11.26734447479248
        },
        {
            "x": 15.981133460998535,
            "y": -8.079858779907227,
            "z": 8.906136512756348
        },
        {
            "x": 18.786949157714844,
            "y": -3.446110725402832,
            "z": 5.930841445922852
        },
        {
            "x": 19.753767013549805,
            "y": 1.6904380321502686,
            "z": 2.6327011585235596
        },
        {
            "x": 18.786949157714844,
            "y": 6.826986789703369,
            "z": -0.6654388308525085
        },
        {
            "x": 15.981133460998535,
            "y": 11.460734367370605,
            "z": -3.6407339572906494
        },
        {
            "x": 11.61097240447998,
            "y": 15.138097763061523,
            "z": -6.001942157745361
        },
        {
            "x": 6.104249477386475,
            "y": 17.49911117553711,
            "z": -7.517930507659912
        },
        {
            "x": 3.628708204278736e-15,
            "y": 18.312660217285156,
            "z": -8.040304183959961
        },
        {
            "x": -6.104249477386475,
            "y": 17.49911117553711,
            "z": -7.517930507659912
        },
        {
            "x": -11.61097240447998,
            "y": 15.138097763061523,
            "z": -6.001942157745361
        },
        {
            "x": -15.981133460998535,
            "y": 11.460734367370605,
            "z": -3.6407339572906494
        },
        {
            "x": -18.786949157714844,
            "y": 6.826986789703369,
            "z": -0.6654388308525085
        },
        {
            "x": -20,
            "y": 6.616794802637204e-16,
            "z": 1.0305047305525549e-15
        },
        {
            "x": -19.021129608154297,
            "y": -5.2005767822265625,
            "z": 3.339251756668091
        },
        {
            "x": -16.180339813232422,
            "y": -9.892084121704102,
            "z": 6.351634502410889
        },
        {
            "x": -11.755704879760742,
            "y": -13.615286827087402,
            "z": 8.74227523803711
        },
        {
            "x": -6.180339813232422,
            "y": -16.005727767944336,
            "z": 10.27716064453125
        },
        {
            "x": -1.2246467996456087e-15,
            "y": -16.82942008972168,
            "z": 10.806046485900879
        },
        {
            "x": 6.180339813232422,
            "y": -16.005727767944336,
            "z": 10.27716064453125
        },
        {
            "x": 11.755704879760742,
            "y": -13.615286827087402,
            "z": 8.74227523803711
        },
        {
            "x": 16.180339813232422,
            "y": -9.892084121704102,
            "z": 6.351634502410889
        },
        {
            "x": 19.021129608154297,
            "y": -5.2005767822265625,
            "z": 3.339251756668091
        },
        {
            "x": 20,
            "y": -1.3993299808413894e-15,
            "z": 2.3538636910799956e-15
        },
        {
            "x": 19.021129608154297,
            "y": 5.2005767822265625,
            "z": -3.339251756668091
        },
        {
            "x": 16.180339813232422,
            "y": 9.892084121704102,
            "z": -6.351634502410889
        },
        {
            "x": 11.755704879760742,
            "y": 13.615286827087402,
            "z": -8.74227523803711
        },
        {
            "x": 6.180339813232422,
            "y": 16.005727767944336,
            "z": -10.27716064453125
        },
        {
            "x": 3.673940187178589e-15,
            "y": 16.82942008972168,
            "z": -10.806046485900879
        },
        {
            "x": -6.180339813232422,
            "y": 16.005727767944336,
            "z": -10.27716064453125
        },
        {
            "x": -11.755704879760742,
            "y": 13.615286827087402,
            "z": -8.74227523803711
        },
        {
            "x": -16.180339813232422,
            "y": 9.892084121704102,
            "z": -6.351634502410889
        },
        {
            "x": -19.021129608154297,
            "y": 5.2005767822265625,
            "z": -3.339251756668091
        },
        {
            "x": -20,
            "y": 4.783698614232177e-15,
            "z": -1.6162131905023266e-15
        },
        {
            "x": -19.753767013549805,
            "y": -1.6904380321502686,
            "z": -2.6327011585235596
        },
        {
            "x": -18.786949157714844,
            "y": -6.826986789703369,
            "z": 0.6654388308525085
        },
        {
            "x": -15.981133460998535,
            "y": -11.460734367370605,
            "z": 3.6407339572906494
        },
        {
            "x": -11.61097240447998,
            "y": -15.138097763061523,
            "z": 6.001942157745361
        },
        {
            "x": -6.104249477386475,
            "y": -17.49911117553711,
            "z": 7.517930507659912
        },
        {
            "x": -1.2095694014262453e-15,
            "y": -18.312660217285156,
            "z": 8.040304183959961
        },
        {
            "x": 6.104249477386475,
            "y": -17.49911117553711,
            "z": 7.517930507659912
        },
        {
            "x": 11.61097240447998,
            "y": -15.138097763061523,
            "z": 6.001942157745361
        },
        {
            "x": 15.981133460998535,
            "y": -11.460734367370605,
            "z": 3.6407339572906494
        },
        {
            "x": 18.786949157714844,
            "y": -6.826986789703369,
            "z": 0.6654388308525085
        },
        {
            "x": 19.753767013549805,
            "y": -1.6904380321502686,
            "z": -2.6327011585235596
        },
        {
            "x": 18.786949157714844,
            "y": 3.446110725402832,
            "z": -5.930841445922852
        },
        {
            "x": 15.981133460998535,
            "y": 8.079858779907227,
            "z": -8.906136512756348
        },
        {
            "x": 11.61097240447998,
            "y": 11.757222175598145,
            "z": -11.26734447479248
        },
        {
            "x": 6.104249477386475,
            "y": 14.118234634399414,
            "z": -12.783332824707031
        },
        {
            "x": 3.628708204278736e-15,
            "y": 14.931783676147461,
            "z": -13.305706977844238
        },
        {
            "x": -6.104249477386475,
            "y": 14.118234634399414,
            "z": -12.783332824707031
        },
        {
            "x": -11.61097240447998,
            "y": 11.757222175598145,
            "z": -11.26734447479248
        },
        {
            "x": -15.981133460998535,
            "y": 8.079858779907227,
            "z": -8.906136512756348
        },
        {
            "x": -18.786949157714844,
            "y": 3.446110725402832,
            "z": -5.930841445922852
        },
        {
            "x": -19.021129608154297,
            "y": -3.339251756668091,
            "z": -5.2005767822265625
        },
        {
            "x": -18.09016990661621,
            "y": -8.285294532775879,
            "z": -2.024759292602539
        },
        {
            "x": -15.38841724395752,
            "y": -12.747183799743652,
            "z": 0.8401867747306824
        },
        {
            "x": -11.180339813232422,
            "y": -16.288158416748047,
            "z": 3.113820791244507
        },
        {
            "x": -5.877852439880371,
            "y": -18.56160545349121,
            "z": 4.573584079742432
        },
        {
            "x": -1.164708313078171e-15,
            "y": -19.344980239868164,
            "z": 5.076583385467529
        },
        {
            "x": 5.877852439880371,
            "y": -18.56160545349121,
            "z": 4.573584079742432
        },
        {
            "x": 11.180339813232422,
            "y": -16.288158416748047,
            "z": 3.113820791244507
        },
        {
            "x": 15.38841724395752,
            "y": -12.747183799743652,
            "z": 0.8401867747306824
        },
        {
            "x": 18.09016990661621,
            "y": -8.285294532775879,
            "z": -2.024759292602539
        },
        {
            "x": 19.021129608154297,
            "y": -3.339251756668091,
            "z": -5.2005767822265625
        },
        {
            "x": 18.09016990661621,
            "y": 1.6067904233932495,
            "z": -8.376394271850586
        },
        {
            "x": 15.38841724395752,
            "y": 6.0686798095703125,
            "z": -11.241339683532715
        },
        {
            "x": 11.180339813232422,
            "y": 9.609654426574707,
            "z": -13.514973640441895
        },
        {
            "x": 5.877852439880371,
            "y": 11.883101463317871,
            "z": -14.974737167358398
        },
        {
            "x": 3.4941250451136314e-15,
            "y": 12.66647720336914,
            "z": -15.477736473083496
        },
        {
            "x": -5.877852439880371,
            "y": 11.883101463317871,
            "z": -14.974737167358398
        },
        {
            "x": -11.180339813232422,
            "y": 9.609654426574707,
            "z": -13.514973640441895
        },
        {
            "x": -15.38841724395752,
            "y": 6.0686798095703125,
            "z": -11.241339683532715
        },
        {
            "x": -18.09016990661621,
            "y": 1.6067904233932495,
            "z": -8.376394271850586
        },
        {
            "x": -17.820131301879883,
            "y": -4.905842304229736,
            "z": -7.640396595001221
        },
        {
            "x": -16.94795036315918,
            "y": -9.539589881896973,
            "z": -4.665101528167725
        },
        {
            "x": -14.416788101196289,
            "y": -13.719754219055176,
            "z": -1.981048822402954
        },
        {
            "x": -10.474410057067871,
            "y": -17.037151336669922,
            "z": 0.14902706444263458
        },
        {
            "x": -5.506722927093506,
            "y": -19.167051315307617,
            "z": 1.5166199207305908
        },
        {
            "x": -1.0911682773228422e-15,
            "y": -19.90096664428711,
            "z": 1.9878612756729126
        },
        {
            "x": 5.506722927093506,
            "y": -19.167051315307617,
            "z": 1.5166199207305908
        },
        {
            "x": 10.474410057067871,
            "y": -17.037151336669922,
            "z": 0.14902706444263458
        },
        {
            "x": 14.416788101196289,
            "y": -13.719754219055176,
            "z": -1.981048822402954
        },
        {
            "x": 16.94795036315918,
            "y": -9.539589881896973,
            "z": -4.665101528167725
        },
        {
            "x": 17.820131301879883,
            "y": -4.905842304229736,
            "z": -7.640396595001221
        },
        {
            "x": 16.94795036315918,
            "y": -0.2720947861671448,
            "z": -10.615692138671875
        },
        {
            "x": 14.416788101196289,
            "y": 3.9080698490142822,
            "z": -13.299744606018066
        },
        {
            "x": 10.474410057067871,
            "y": 7.225466728210449,
            "z": -15.429821014404297
        },
        {
            "x": 5.506722927093506,
            "y": 9.355365753173828,
            "z": -16.797412872314453
        },
        {
            "x": 3.273504937847645e-15,
            "y": 10.08928108215332,
            "z": -17.268653869628906
        },
        {
            "x": -5.506722927093506,
            "y": 9.355365753173828,
            "z": -16.797412872314453
        },
        {
            "x": -10.474410057067871,
            "y": 7.225466728210449,
            "z": -15.429821014404297
        },
        {
            "x": -14.416788101196289,
            "y": 3.9080698490142822,
            "z": -13.299744606018066
        },
        {
            "x": -16.94795036315918,
            "y": -0.2720947861671448,
            "z": -10.615692138671875
        },
        {
            "x": -16.180339813232422,
            "y": -6.351634502410889,
            "z": -9.892084121704102
        },
        {
            "x": -15.38841724395752,
            "y": -10.558989524841309,
            "z": -7.190573215484619
        },
        {
            "x": -13.090169906616211,
            "y": -14.354498863220215,
            "z": -4.753504276275635
        },
        {
            "x": -9.510564804077148,
            "y": -17.36663246154785,
            "z": -2.8194355964660645
        },
        {
            "x": -5,
            "y": -19.300540924072266,
            "z": -1.5776872634887695
        },
        {
            "x": -9.907600388432518e-16,
            "y": -19.966920852661133,
            "z": -1.1498095989227295
        },
        {
            "x": 5,
            "y": -19.300540924072266,
            "z": -1.5776872634887695
        },
        {
            "x": 9.510564804077148,
            "y": -17.36663246154785,
            "z": -2.8194355964660645
        },
        {
            "x": 13.090169906616211,
            "y": -14.354498863220215,
            "z": -4.753504276275635
        },
        {
            "x": 15.38841724395752,
            "y": -10.558989524841309,
            "z": -7.190573215484619
        },
        {
            "x": 16.180339813232422,
            "y": -6.351634502410889,
            "z": -9.892084121704102
        },
        {
            "x": 15.38841724395752,
            "y": -2.1442794799804688,
            "z": -12.593596458435059
        },
        {
            "x": 13.090169906616211,
            "y": 1.6512298583984375,
            "z": -15.030664443969727
        },
        {
            "x": 9.510564804077148,
            "y": 4.663363933563232,
            "z": -16.964733123779297
        },
        {
            "x": 5,
            "y": 6.5972723960876465,
            "z": -18.20648193359375
        },
        {
            "x": 2.9722801165297554e-15,
            "y": 7.2636518478393555,
            "z": -18.63435935974121
        },
        {
            "x": -5,
            "y": 6.5972723960876465,
            "z": -18.20648193359375
        },
        {
            "x": -9.510564804077148,
            "y": 4.663363933563232,
            "z": -16.964733123779297
        },
        {
            "x": -13.090169906616211,
            "y": 1.6512298583984375,
            "z": -15.030664443969727
        },
        {
            "x": -15.38841724395752,
            "y": -2.1442794799804688,
            "z": -12.593596458435059
        },
        {
            "x": -14.142135620117188,
            "y": -7.64102840423584,
            "z": -11.90019702911377
        },
        {
            "x": -13.449970245361328,
            "y": -11.318391799926758,
            "z": -9.538989067077637
        },
        {
            "x": -11.441227912902832,
            "y": -14.635788917541504,
            "z": -7.408912658691406
        },
        {
            "x": -8.312539100646973,
            "y": -17.268489837646484,
            "z": -5.718474864959717
        },
        {
            "x": -4.370160102844238,
            "y": -18.95878791809082,
            "z": -4.6331467628479
        },
        {
            "x": -8.659560338728758e-16,
            "y": -19.54122543334961,
            "z": -4.2591681480407715
        },
        {
            "x": 4.370160102844238,
            "y": -18.95878791809082,
            "z": -4.6331467628479
        },
        {
            "x": 8.312539100646973,
            "y": -17.268489837646484,
            "z": -5.718474864959717
        },
        {
            "x": 11.441227912902832,
            "y": -14.635788917541504,
            "z": -7.408912658691406
        },
        {
            "x": 13.449970245361328,
            "y": -11.318391799926758,
            "z": -9.538989067077637
        },
        {
            "x": 14.142135620117188,
            "y": -7.64102840423584,
            "z": -11.90019702911377
        },
        {
            "x": 13.449970245361328,
            "y": -3.96366548538208,
            "z": -14.261404037475586
        },
        {
            "x": 11.441227912902832,
            "y": -0.6462680101394653,
            "z": -16.391481399536133
        },
        {
            "x": 8.312539100646973,
            "y": 1.9864327907562256,
            "z": -18.081918716430664
        },
        {
            "x": 4.370160102844238,
            "y": 3.6767311096191406,
            "z": -19.167245864868164
        },
        {
            "x": 2.597868260437305e-15,
            "y": 4.2591681480407715,
            "z": -19.54122543334961
        },
        {
            "x": -4.370160102844238,
            "y": 3.6767311096191406,
            "z": -19.167245864868164
        },
        {
            "x": -8.312539100646973,
            "y": 1.9864327907562256,
            "z": -18.081918716430664
        },
        {
            "x": -11.441227912902832,
            "y": -0.6462680101394653,
            "z": -16.391481399536133
        },
        {
            "x": -13.449970245361328,
            "y": -3.96366548538208,
            "z": -14.261404037475586
        },
        {
            "x": -11.755704879760742,
            "y": -8.74227523803711,
            "z": -13.615286827087402
        },
        {
            "x": -11.180339813232422,
            "y": -11.799097061157227,
            "z": -11.652523040771484
        },
        {
            "x": -9.510564804077148,
            "y": -14.556696891784668,
            "z": -9.881889343261719
        },
        {
            "x": -6.909830093383789,
            "y": -16.745140075683594,
            "z": -8.476706504821777
        },
        {
            "x": -3.6327126026153564,
            "y": -18.150205612182617,
            "z": -7.574522972106934
        },
        {
            "x": -7.198293508839122e-16,
            "y": -18.63435935974121,
            "z": -7.2636518478393555
        },
        {
            "x": 3.6327126026153564,
            "y": -18.150205612182617,
            "z": -7.574522972106934
        },
        {
            "x": 6.909830093383789,
            "y": -16.745140075683594,
            "z": -8.476706504821777
        },
        {
            "x": 9.510564804077148,
            "y": -14.556696891784668,
            "z": -9.881889343261719
        },
        {
            "x": 11.180339813232422,
            "y": -11.799097061157227,
            "z": -11.652523040771484
        },
        {
            "x": 11.755704879760742,
            "y": -8.74227523803711,
            "z": -13.615286827087402
        },
        {
            "x": 11.180339813232422,
            "y": -5.685452461242676,
            "z": -15.578049659729004
        },
        {
            "x": 9.510564804077148,
            "y": -2.9278533458709717,
            "z": -17.348684310913086
        },
        {
            "x": 6.909830093383789,
            "y": -0.7394105792045593,
            "z": -18.75386619567871
        },
        {
            "x": 3.6327126026153564,
            "y": 0.6656566262245178,
            "z": -19.656049728393555
        },
        {
            "x": 2.1594880526517365e-15,
            "y": 1.1498095989227295,
            "z": -19.966920852661133
        },
        {
            "x": -3.6327126026153564,
            "y": 0.6656566262245178,
            "z": -19.656049728393555
        },
        {
            "x": -6.909830093383789,
            "y": -0.7394105792045593,
            "z": -18.75386619567871
        },
        {
            "x": -9.510564804077148,
            "y": -2.9278533458709717,
            "z": -17.348684310913086
        },
        {
            "x": -11.180339813232422,
            "y": -5.685452461242676,
            "z": -15.578049659729004
        },
        {
            "x": -9.07981014251709,
            "y": -9.628257751464844,
            "z": -14.995123863220215
        },
        {
            "x": -8.635412216186523,
            "y": -11.989270210266113,
            "z": -13.479134559631348
        },
        {
            "x": -7.3457207679748535,
            "y": -14.119170188903809,
            "z": -12.111541748046875
        },
        {
            "x": -5.336978435516357,
            "y": -15.809469223022461,
            "z": -11.026213645935059
        },
        {
            "x": -2.8058156967163086,
            "y": -16.89470672607422,
            "z": -10.329390525817871
        },
        {
            "x": -5.559780270176194e-16,
            "y": -17.268653869628906,
            "z": -10.08928108215332
        },
        {
            "x": 2.8058156967163086,
            "y": -16.89470672607422,
            "z": -10.329390525817871
        },
        {
            "x": 5.336978435516357,
            "y": -15.809469223022461,
            "z": -11.026213645935059
        },
        {
            "x": 7.3457207679748535,
            "y": -14.119170188903809,
            "z": -12.111541748046875
        },
        {
            "x": 8.635412216186523,
            "y": -11.989270210266113,
            "z": -13.479134559631348
        },
        {
            "x": 9.07981014251709,
            "y": -9.628257751464844,
            "z": -14.995123863220215
        },
        {
            "x": 8.635412216186523,
            "y": -7.267245769500732,
            "z": -16.511112213134766
        },
        {
            "x": 7.3457207679748535,
            "y": -5.137345314025879,
            "z": -17.878705978393555
        },
        {
            "x": 5.336978435516357,
            "y": -3.447047233581543,
            "z": -18.964033126831055
        },
        {
            "x": 2.8058156967163086,
            "y": -2.361809253692627,
            "z": -19.660856246948242
        },
        {
            "x": 1.6679340810528583e-15,
            "y": -1.9878612756729126,
            "z": -19.90096664428711
        },
        {
            "x": -2.8058156967163086,
            "y": -2.361809253692627,
            "z": -19.660856246948242
        },
        {
            "x": -5.336978435516357,
            "y": -3.447047233581543,
            "z": -18.964033126831055
        },
        {
            "x": -7.3457207679748535,
            "y": -5.137345314025879,
            "z": -17.878705978393555
        },
        {
            "x": -8.635412216186523,
            "y": -7.267245769500732,
            "z": -16.511112213134766
        },
        {
            "x": -6.180339813232422,
            "y": -10.27716064453125,
            "z": -16.005727767944336
        },
        {
            "x": -5.877852439880371,
            "y": -11.88422679901123,
            "z": -14.97384262084961
        },
        {
            "x": -5,
            "y": -13.333982467651367,
            "z": -14.04296588897705
        },
        {
            "x": -3.6327126026153564,
            "y": -14.484515190124512,
            "z": -13.304217338562012
        },
        {
            "x": -1.909830093383789,
            "y": -15.2232027053833,
            "z": -12.829911231994629
        },
        {
            "x": -3.7843666549022706e-16,
            "y": -15.477736473083496,
            "z": -12.66647720336914
        },
        {
            "x": 1.909830093383789,
            "y": -15.2232027053833,
            "z": -12.829911231994629
        },
        {
            "x": 3.6327126026153564,
            "y": -14.484515190124512,
            "z": -13.304217338562012
        },
        {
            "x": 5,
            "y": -13.333982467651367,
            "z": -14.04296588897705
        },
        {
            "x": 5.877852439880371,
            "y": -11.88422679901123,
            "z": -14.97384262084961
        },
        {
            "x": 6.180339813232422,
            "y": -10.27716064453125,
            "z": -16.005727767944336
        },
        {
            "x": 5.877852439880371,
            "y": -8.670093536376953,
            "z": -17.037614822387695
        },
        {
            "x": 5,
            "y": -7.220337867736816,
            "z": -17.96849250793457
        },
        {
            "x": 3.6327126026153564,
            "y": -6.069805145263672,
            "z": -18.70724105834961
        },
        {
            "x": 1.909830093383789,
            "y": -5.331118106842041,
            "z": -19.18154525756836
        },
        {
            "x": 1.1353100229404608e-15,
            "y": -5.076583385467529,
            "z": -19.344980239868164
        },
        {
            "x": -1.909830093383789,
            "y": -5.331118106842041,
            "z": -19.18154525756836
        },
        {
            "x": -3.6327126026153564,
            "y": -6.069805145263672,
            "z": -18.70724105834961
        },
        {
            "x": -5,
            "y": -7.220337867736816,
            "z": -17.96849250793457
        },
        {
            "x": -5.877852439880371,
            "y": -8.670093536376953,
            "z": -17.037614822387695
        },
        {
            "x": -3.1286892890930176,
            "y": -10.673006057739258,
            "z": -16.622220993041992
        },
        {
            "x": -2.975560426712036,
            "y": -11.486555099487305,
            "z": -16.0998477935791
        },
        {
            "x": -2.531162738800049,
            "y": -12.220468521118164,
            "z": -15.628606796264648
        },
        {
            "x": -1.838997483253479,
            "y": -12.802906036376953,
            "z": -15.254629135131836
        },
        {
            "x": -0.9668181538581848,
            "y": -13.17685317993164,
            "z": -15.014519691467285
        },
        {
            "x": -1.9157696216119213e-16,
            "y": -13.305706977844238,
            "z": -14.931783676147461
        },
        {
            "x": 0.9668181538581848,
            "y": -13.17685317993164,
            "z": -15.014519691467285
        },
        {
            "x": 1.838997483253479,
            "y": -12.802906036376953,
            "z": -15.254629135131836
        },
        {
            "x": 2.531162738800049,
            "y": -12.220468521118164,
            "z": -15.628606796264648
        },
        {
            "x": 2.975560426712036,
            "y": -11.486555099487305,
            "z": -16.0998477935791
        },
        {
            "x": 3.1286892890930176,
            "y": -10.673006057739258,
            "z": -16.622220993041992
        },
        {
            "x": 2.975560426712036,
            "y": -9.859456062316895,
            "z": -17.144596099853516
        },
        {
            "x": 2.531162738800049,
            "y": -9.125542640686035,
            "z": -17.61583709716797
        },
        {
            "x": 1.838997483253479,
            "y": -8.543106079101562,
            "z": -17.98981475830078
        },
        {
            "x": 0.9668181538581848,
            "y": -8.169157981872559,
            "z": -18.229923248291016
        },
        {
            "x": 5.74730912953356e-16,
            "y": -8.040304183959961,
            "z": -18.312660217285156
        },
        {
            "x": -0.9668181538581848,
            "y": -8.169157981872559,
            "z": -18.229923248291016
        },
        {
            "x": -1.838997483253479,
            "y": -8.543106079101562,
            "z": -17.98981475830078
        },
        {
            "x": -2.531162738800049,
            "y": -9.125542640686035,
            "z": -17.61583709716797
        },
        {
            "x": -2.975560426712036,
            "y": -9.859456062316895,
            "z": -17.144596099853516
        },
        {
            "x": -2.4492935992912173e-15,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": -2.329416626156342e-15,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": -1.9815200776865036e-15,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": -1.4396587017678243e-15,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": -7.568733309804541e-16,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": -1.4997597572211942e-31,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": 7.568733309804541e-16,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": 1.4396587017678243e-15,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": 1.9815200776865036e-15,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": 2.329416626156342e-15,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": 2.4492935992912173e-15,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": 4.499279389213018e-31,
            "y": -10.806046485900879,
            "z": -16.82942008972168
        },
        {
            "x": 0,
            "y": 8.104534149169922,
            "z": 12.622064590454102
        },
        {
            "x": -3.118675470352173,
            "y": 7.927430629730225,
            "z": 12.34624195098877
        },
        {
            "x": -2.8490517139434814,
            "y": 6.860042095184326,
            "z": 13.031604766845703
        },
        {
            "x": -2.086801052093506,
            "y": 5.977214813232422,
            "z": 13.598461151123047
        },
        {
            "x": -0.9637236595153809,
            "y": 5.4315972328186035,
            "z": 13.948798179626465
        },
        {
            "x": 0.32599034905433655,
            "y": 5.317532062530518,
            "z": 14.022038459777832
        },
        {
            "x": 1.5593377351760864,
            "y": 5.654742240905762,
            "z": 13.805519104003906
        },
        {
            "x": 2.5230612754821777,
            "y": 6.384920597076416,
            "z": 13.336676597595215
        },
        {
            "x": 3.0505247116088867,
            "y": 7.3818135261535645,
            "z": 12.696578979492188
        },
        {
            "x": 3.0505247116088867,
            "y": 8.473048210144043,
            "z": 11.995904922485352
        },
        {
            "x": 2.5230612754821777,
            "y": 9.469941139221191,
            "z": 11.355807304382324
        },
        {
            "x": 1.5593377351760864,
            "y": 10.200119972229004,
            "z": 10.88696575164795
        },
        {
            "x": 0.32599034905433655,
            "y": 10.53732967376709,
            "z": 10.670445442199707
        },
        {
            "x": -0.9637236595153809,
            "y": 10.423264503479004,
            "z": 10.743685722351074
        },
        {
            "x": -2.086801052093506,
            "y": 9.877647399902344,
            "z": 11.094022750854492
        },
        {
            "x": -2.8490517139434814,
            "y": 8.994819641113281,
            "z": 11.660879135131836
        },
        {
            "x": -6.101049423217773,
            "y": 7.403861045837402,
            "z": 11.530830383300781
        },
        {
            "x": -5.5735859870910645,
            "y": 5.315733432769775,
            "z": 12.871601104736328
        },
        {
            "x": -4.082398891448975,
            "y": 3.5886621475219727,
            "z": 13.980541229248047
        },
        {
            "x": -1.8853280544281006,
            "y": 2.521273612976074,
            "z": 14.66590404510498
        },
        {
            "x": 0.6377333402633667,
            "y": 2.298128604888916,
            "z": 14.809183120727539
        },
        {
            "x": 3.0505247116088867,
            "y": 2.957811117172241,
            "z": 14.385605812072754
        },
        {
            "x": 4.935853004455566,
            "y": 4.386255741119385,
            "z": 13.468412399291992
        },
        {
            "x": 5.967727184295654,
            "y": 6.336472034454346,
            "z": 12.216192245483398
        },
        {
            "x": 5.967727184295654,
            "y": 8.4712495803833,
            "z": 10.845467567443848
        },
        {
            "x": 4.935853004455566,
            "y": 10.421465873718262,
            "z": 9.59324836730957
        },
        {
            "x": 3.0505247116088867,
            "y": 11.849910736083984,
            "z": 8.676054954528809
        },
        {
            "x": 0.6377333402633667,
            "y": 12.50959300994873,
            "z": 8.252476692199707
        },
        {
            "x": -1.8853280544281006,
            "y": 12.28644847869873,
            "z": 8.395756721496582
        },
        {
            "x": -4.082398891448975,
            "y": 11.219059944152832,
            "z": 9.081119537353516
        },
        {
            "x": -5.5735859870910645,
            "y": 9.491988182067871,
            "z": 10.190058708190918
        },
        {
            "x": -8.816779136657715,
            "y": 6.556705951690674,
            "z": 10.211464881896973
        },
        {
            "x": -8.05452823638916,
            "y": 3.5391011238098145,
            "z": 12.149046897888184
        },
        {
            "x": -5.899576663970947,
            "y": 1.0432674884796143,
            "z": 13.751603126525879
        },
        {
            "x": -2.724534511566162,
            "y": -0.49924248456954956,
            "z": 14.742037773132324
        },
        {
            "x": 0.921604335308075,
            "y": -0.8217147588729858,
            "z": 14.949094772338867
        },
        {
            "x": 4.408389568328857,
            "y": 0.1316087692975998,
            "z": 14.3369722366333
        },
        {
            "x": 7.1329240798950195,
            "y": 2.195889949798584,
            "z": 13.011512756347656
        },
        {
            "x": 8.62411117553711,
            "y": 5.014195919036865,
            "z": 11.201899528503418
        },
        {
            "x": 8.62411117553711,
            "y": 8.09921646118164,
            "z": 9.221030235290527
        },
        {
            "x": 7.1329240798950195,
            "y": 10.917522430419922,
            "z": 7.411417007446289
        },
        {
            "x": 4.408389568328857,
            "y": 12.981803894042969,
            "z": 6.085957050323486
        },
        {
            "x": 0.921604335308075,
            "y": 13.935127258300781,
            "z": 5.473835468292236
        },
        {
            "x": -2.724534511566162,
            "y": 13.612654685974121,
            "z": 5.680892467498779
        },
        {
            "x": -5.899576663970947,
            "y": 12.070144653320312,
            "z": 6.671326637268066
        },
        {
            "x": -8.05452823638916,
            "y": 9.574311256408691,
            "z": 8.273882865905762
        },
        {
            "x": -11.147171974182129,
            "y": 5.422991752624512,
            "z": 8.445809364318848
        },
        {
            "x": -10.183448791503906,
            "y": 1.6077930927276611,
            "z": 10.895520210266113
        },
        {
            "x": -7.458914279937744,
            "y": -1.5477226972579956,
            "z": 12.921653747558594
        },
        {
            "x": -3.4446656703948975,
            "y": -3.497939348220825,
            "z": 14.173873901367188
        },
        {
            "x": 1.1651967763900757,
            "y": -3.9056456089019775,
            "z": 14.43565845489502
        },
        {
            "x": 5.5735859870910645,
            "y": -2.700345277786255,
            "z": 13.661744117736816
        },
        {
            "x": 9.0182523727417,
            "y": -0.09044677764177322,
            "z": 11.985947608947754
        },
        {
            "x": 10.903579711914062,
            "y": 3.47277569770813,
            "z": 9.698028564453125
        },
        {
            "x": 10.903579711914062,
            "y": 7.373208045959473,
            "z": 7.19359016418457
        },
        {
            "x": 9.0182523727417,
            "y": 10.936430931091309,
            "z": 4.905671119689941
        },
        {
            "x": 5.5735859870910645,
            "y": 13.546329498291016,
            "z": 3.229874610900879
        },
        {
            "x": 1.1651967763900757,
            "y": 14.751629829406738,
            "z": 2.455960273742676
        },
        {
            "x": -3.4446656703948975,
            "y": 14.343923568725586,
            "z": 2.717745304107666
        },
        {
            "x": -7.458914279937744,
            "y": 12.393706321716309,
            "z": 3.9699649810791016
        },
        {
            "x": -10.183448791503906,
            "y": 9.238190650939941,
            "z": 5.996098518371582
        },
        {
            "x": -12.990381240844727,
            "y": 4.052267074584961,
            "z": 6.311032295227051
        },
        {
            "x": -11.867303848266602,
            "y": -0.39378243684768677,
            "z": 9.165807723999023
        },
        {
            "x": -8.692261695861816,
            "y": -4.071069717407227,
            "z": 11.52696704864502
        },
        {
            "x": -4.014248371124268,
            "y": -6.343759059906006,
            "z": 12.986244201660156
        },
        {
            "x": 1.3578646183013916,
            "y": -6.818880081176758,
            "z": 13.291316032409668
        },
        {
            "x": 6.495190620422363,
            "y": -5.414281368255615,
            "z": 12.389432907104492
        },
        {
            "x": 10.509439468383789,
            "y": -2.3728301525115967,
            "z": 10.436539649963379
        },
        {
            "x": 12.706509590148926,
            "y": 1.7795785665512085,
            "z": 7.770308971405029
        },
        {
            "x": 12.706509590148926,
            "y": 6.324955940246582,
            "z": 4.851755619049072
        },
        {
            "x": 10.509439468383789,
            "y": 10.477364540100098,
            "z": 2.1855247020721436
        },
        {
            "x": 6.495190620422363,
            "y": 13.518815994262695,
            "z": 0.23263144493103027
        },
        {
            "x": 1.3578646183013916,
            "y": 14.92341423034668,
            "z": -0.6692509055137634
        },
        {
            "x": -4.014248371124268,
            "y": 14.448293685913086,
            "z": -0.36417925357818604
        },
        {
            "x": -8.692261695861816,
            "y": 12.175604820251465,
            "z": 1.095097541809082
        },
        {
            "x": -11.867303848266602,
            "y": 8.498316764831543,
            "z": 3.456256628036499
        },
        {
            "x": -14.265848159790039,
            "y": 2.504438877105713,
            "z": 3.900432586669922
        },
        {
            "x": -13.032500267028809,
            "y": -2.3781485557556152,
            "z": 7.035505771636963
        },
        {
            "x": -9.54571533203125,
            "y": -6.416492462158203,
            "z": 9.628496170043945
        },
        {
            "x": -4.408389568328857,
            "y": -8.912325859069824,
            "z": 11.231053352355957
        },
        {
            "x": 1.4911870956420898,
            "y": -9.434097290039062,
            "z": 11.566078186035156
        },
        {
            "x": 7.1329240798950195,
            "y": -7.891587257385254,
            "z": 10.575644493103027
        },
        {
            "x": 11.541313171386719,
            "y": -4.551509857177734,
            "z": 8.431005477905273
        },
        {
            "x": 13.95410442352295,
            "y": 0.008605185896158218,
            "z": 5.502988815307617
        },
        {
            "x": 13.95410442352295,
            "y": 5.000272750854492,
            "z": 2.2978761196136475
        },
        {
            "x": 11.541313171386719,
            "y": 9.56038761138916,
            "z": -0.6301400661468506
        },
        {
            "x": 7.1329240798950195,
            "y": 12.90046501159668,
            "z": -2.7747790813446045
        },
        {
            "x": 1.4911870956420898,
            "y": 14.442975044250488,
            "z": -3.76521372795105
        },
        {
            "x": -4.408389568328857,
            "y": 13.921204566955566,
            "z": -3.4301881790161133
        },
        {
            "x": -9.54571533203125,
            "y": 11.425370216369629,
            "z": -1.8276315927505493
        },
        {
            "x": -13.032500267028809,
            "y": 7.387026309967041,
            "z": 0.765359103679657
        },
        {
            "x": -14.917828559875488,
            "y": 0.8471545577049255,
            "z": 1.3193650245666504
        },
        {
            "x": -13.628114700317383,
            "y": -4.258577823638916,
            "z": 4.597718238830566
        },
        {
            "x": -9.981975555419922,
            "y": -8.481483459472656,
            "z": 7.3092145919799805
        },
        {
            "x": -4.609862327575684,
            "y": -11.091382026672363,
            "z": 8.985011100769043
        },
        {
            "x": 1.5593377351760864,
            "y": -11.636999130249023,
            "z": 9.335348129272461
        },
        {
            "x": 7.458914279937744,
            "y": -10.023992538452148,
            "z": 8.29964828491211
        },
        {
            "x": 12.068777084350586,
            "y": -6.531266212463379,
            "z": 6.056994915008545
        },
        {
            "x": 14.591837882995605,
            "y": -1.7627441883087158,
            "z": 2.995161771774292
        },
        {
            "x": 14.591837882995605,
            "y": 3.4570531845092773,
            "z": -0.35643163323402405
        },
        {
            "x": 12.068777084350586,
            "y": 8.22557544708252,
            "z": -3.418264627456665
        },
        {
            "x": 7.458914279937744,
            "y": 11.718301773071289,
            "z": -5.660918235778809
        },
        {
            "x": 1.5593377351760864,
            "y": 13.331308364868164,
            "z": -6.69661808013916
        },
        {
            "x": -4.609862327575684,
            "y": 12.785691261291504,
            "z": -6.346281051635742
        },
        {
            "x": -9.981975555419922,
            "y": 10.17579174041748,
            "z": -4.6704840660095215
        },
        {
            "x": -13.628114700317383,
            "y": 5.952887058258057,
            "z": -1.958988070487976
        },
        {
            "x": -14.917828559875488,
            "y": -0.8471545577049255,
            "z": -1.3193650245666504
        },
        {
            "x": -13.628114700317383,
            "y": -5.952887058258057,
            "z": 1.958988070487976
        },
        {
            "x": -9.981975555419922,
            "y": -10.17579174041748,
            "z": 4.6704840660095215
        },
        {
            "x": -4.609862327575684,
            "y": -12.785691261291504,
            "z": 6.346281051635742
        },
        {
            "x": 1.5593377351760864,
            "y": -13.331308364868164,
            "z": 6.69661808013916
        },
        {
            "x": 7.458914279937744,
            "y": -11.718301773071289,
            "z": 5.660918235778809
        },
        {
            "x": 12.068777084350586,
            "y": -8.22557544708252,
            "z": 3.418264627456665
        },
        {
            "x": 14.591837882995605,
            "y": -3.4570531845092773,
            "z": 0.35643163323402405
        },
        {
            "x": 14.591837882995605,
            "y": 1.7627441883087158,
            "z": -2.995161771774292
        },
        {
            "x": 12.068777084350586,
            "y": 6.531266212463379,
            "z": -6.056994915008545
        },
        {
            "x": 7.458914279937744,
            "y": 10.023992538452148,
            "z": -8.29964828491211
        },
        {
            "x": 1.5593377351760864,
            "y": 11.636999130249023,
            "z": -9.335348129272461
        },
        {
            "x": -4.609862327575684,
            "y": 11.091382026672363,
            "z": -8.985011100769043
        },
        {
            "x": -9.981975555419922,
            "y": 8.481483459472656,
            "z": -7.3092145919799805
        },
        {
            "x": -13.628114700317383,
            "y": 4.258577823638916,
            "z": -4.597718238830566
        },
        {
            "x": -14.265848159790039,
            "y": -2.504438877105713,
            "z": -3.900432586669922
        },
        {
            "x": -13.032500267028809,
            "y": -7.387026309967041,
            "z": -0.765359103679657
        },
        {
            "x": -9.54571533203125,
            "y": -11.425370216369629,
            "z": 1.8276315927505493
        },
        {
            "x": -4.408389568328857,
            "y": -13.921204566955566,
            "z": 3.4301881790161133
        },
        {
            "x": 1.4911870956420898,
            "y": -14.442975044250488,
            "z": 3.76521372795105
        },
        {
            "x": 7.1329240798950195,
            "y": -12.90046501159668,
            "z": 2.7747790813446045
        },
        {
            "x": 11.541313171386719,
            "y": -9.56038761138916,
            "z": 0.6301400661468506
        },
        {
            "x": 13.95410442352295,
            "y": -5.000272750854492,
            "z": -2.2978761196136475
        },
        {
            "x": 13.95410442352295,
            "y": -0.008605185896158218,
            "z": -5.502988815307617
        },
        {
            "x": 11.541313171386719,
            "y": 4.551509857177734,
            "z": -8.431005477905273
        },
        {
            "x": 7.1329240798950195,
            "y": 7.891587257385254,
            "z": -10.575644493103027
        },
        {
            "x": 1.4911870956420898,
            "y": 9.434097290039062,
            "z": -11.566078186035156
        },
        {
            "x": -4.408389568328857,
            "y": 8.912325859069824,
            "z": -11.231053352355957
        },
        {
            "x": -9.54571533203125,
            "y": 6.416492462158203,
            "z": -9.628496170043945
        },
        {
            "x": -13.032500267028809,
            "y": 2.3781485557556152,
            "z": -7.035505771636963
        },
        {
            "x": -12.990381240844727,
            "y": -4.052267074584961,
            "z": -6.311032295227051
        },
        {
            "x": -11.867303848266602,
            "y": -8.498316764831543,
            "z": -3.456256628036499
        },
        {
            "x": -8.692261695861816,
            "y": -12.175604820251465,
            "z": -1.095097541809082
        },
        {
            "x": -4.014248371124268,
            "y": -14.448293685913086,
            "z": 0.36417925357818604
        },
        {
            "x": 1.3578646183013916,
            "y": -14.92341423034668,
            "z": 0.6692509055137634
        },
        {
            "x": 6.495190620422363,
            "y": -13.518815994262695,
            "z": -0.23263144493103027
        },
        {
            "x": 10.509439468383789,
            "y": -10.477364540100098,
            "z": -2.1855247020721436
        },
        {
            "x": 12.706509590148926,
            "y": -6.324955940246582,
            "z": -4.851755619049072
        },
        {
            "x": 12.706509590148926,
            "y": -1.7795785665512085,
            "z": -7.770308971405029
        },
        {
            "x": 10.509439468383789,
            "y": 2.3728301525115967,
            "z": -10.436539649963379
        },
        {
            "x": 6.495190620422363,
            "y": 5.414281368255615,
            "z": -12.389432907104492
        },
        {
            "x": 1.3578646183013916,
            "y": 6.818880081176758,
            "z": -13.291316032409668
        },
        {
            "x": -4.014248371124268,
            "y": 6.343759059906006,
            "z": -12.986244201660156
        },
        {
            "x": -8.692261695861816,
            "y": 4.071069717407227,
            "z": -11.52696704864502
        },
        {
            "x": -11.867303848266602,
            "y": 0.39378243684768677,
            "z": -9.165807723999023
        },
        {
            "x": -11.147171974182129,
            "y": -5.422991752624512,
            "z": -8.445809364318848
        },
        {
            "x": -10.183448791503906,
            "y": -9.238190650939941,
            "z": -5.996098518371582
        },
        {
            "x": -7.458914279937744,
            "y": -12.393706321716309,
            "z": -3.9699649810791016
        },
        {
            "x": -3.4446656703948975,
            "y": -14.343923568725586,
            "z": -2.717745304107666
        },
        {
            "x": 1.1651967763900757,
            "y": -14.751629829406738,
            "z": -2.455960273742676
        },
        {
            "x": 5.5735859870910645,
            "y": -13.546329498291016,
            "z": -3.229874610900879
        },
        {
            "x": 9.0182523727417,
            "y": -10.936430931091309,
            "z": -4.905671119689941
        },
        {
            "x": 10.903579711914062,
            "y": -7.373208045959473,
            "z": -7.19359016418457
        },
        {
            "x": 10.903579711914062,
            "y": -3.47277569770813,
            "z": -9.698028564453125
        },
        {
            "x": 9.0182523727417,
            "y": 0.09044677764177322,
            "z": -11.985947608947754
        },
        {
            "x": 5.5735859870910645,
            "y": 2.700345277786255,
            "z": -13.661744117736816
        },
        {
            "x": 1.1651967763900757,
            "y": 3.9056456089019775,
            "z": -14.43565845489502
        },
        {
            "x": -3.4446656703948975,
            "y": 3.497939348220825,
            "z": -14.173873901367188
        },
        {
            "x": -7.458914279937744,
            "y": 1.5477226972579956,
            "z": -12.921653747558594
        },
        {
            "x": -10.183448791503906,
            "y": -1.6077930927276611,
            "z": -10.895520210266113
        },
        {
            "x": -8.816779136657715,
            "y": -6.556705951690674,
            "z": -10.211464881896973
        },
        {
            "x": -8.05452823638916,
            "y": -9.574311256408691,
            "z": -8.273882865905762
        },
        {
            "x": -5.899576663970947,
            "y": -12.070144653320312,
            "z": -6.671326637268066
        },
        {
            "x": -2.724534511566162,
            "y": -13.612654685974121,
            "z": -5.680892467498779
        },
        {
            "x": 0.921604335308075,
            "y": -13.935127258300781,
            "z": -5.473835468292236
        },
        {
            "x": 4.408389568328857,
            "y": -12.981803894042969,
            "z": -6.085957050323486
        },
        {
            "x": 7.1329240798950195,
            "y": -10.917522430419922,
            "z": -7.411417007446289
        },
        {
            "x": 8.62411117553711,
            "y": -8.09921646118164,
            "z": -9.221030235290527
        },
        {
            "x": 8.62411117553711,
            "y": -5.014195919036865,
            "z": -11.201899528503418
        },
        {
            "x": 7.1329240798950195,
            "y": -2.195889949798584,
            "z": -13.011512756347656
        },
        {
            "x": 4.408389568328857,
            "y": -0.1316087692975998,
            "z": -14.3369722366333
        },
        {
            "x": 0.921604335308075,
            "y": 0.8217147588729858,
            "z": -14.949094772338867
        },
        {
            "x": -2.724534511566162,
            "y": 0.49924248456954956,
            "z": -14.742037773132324
        },
        {
            "x": -5.899576663970947,
            "y": -1.0432674884796143,
            "z": -13.751603126525879
        },
        {
            "x": -8.05452823638916,
            "y": -3.5391011238098145,
            "z": -12.149046897888184
        },
        {
            "x": -6.101049423217773,
            "y": -7.403861045837402,
            "z": -11.530830383300781
        },
        {
            "x": -5.5735859870910645,
            "y": -9.491988182067871,
            "z": -10.190058708190918
        },
        {
            "x": -4.082398891448975,
            "y": -11.219059944152832,
            "z": -9.081119537353516
        },
        {
            "x": -1.8853280544281006,
            "y": -12.28644847869873,
            "z": -8.395756721496582
        },
        {
            "x": 0.6377333402633667,
            "y": -12.50959300994873,
            "z": -8.252476692199707
        },
        {
            "x": 3.0505247116088867,
            "y": -11.849910736083984,
            "z": -8.676054954528809
        },
        {
            "x": 4.935853004455566,
            "y": -10.421465873718262,
            "z": -9.59324836730957
        },
        {
            "x": 5.967727184295654,
            "y": -8.4712495803833,
            "z": -10.845467567443848
        },
        {
            "x": 5.967727184295654,
            "y": -6.336472034454346,
            "z": -12.216192245483398
        },
        {
            "x": 4.935853004455566,
            "y": -4.386255741119385,
            "z": -13.468412399291992
        },
        {
            "x": 3.0505247116088867,
            "y": -2.957811117172241,
            "z": -14.385605812072754
        },
        {
            "x": 0.6377333402633667,
            "y": -2.298128604888916,
            "z": -14.809183120727539
        },
        {
            "x": -1.8853280544281006,
            "y": -2.521273612976074,
            "z": -14.66590404510498
        },
        {
            "x": -4.082398891448975,
            "y": -3.5886621475219727,
            "z": -13.980541229248047
        },
        {
            "x": -5.5735859870910645,
            "y": -5.315733432769775,
            "z": -12.871601104736328
        },
        {
            "x": -3.118675470352173,
            "y": -7.927430629730225,
            "z": -12.34624195098877
        },
        {
            "x": -2.8490517139434814,
            "y": -8.994819641113281,
            "z": -11.660879135131836
        },
        {
            "x": -2.086801052093506,
            "y": -9.877647399902344,
            "z": -11.094022750854492
        },
        {
            "x": -0.9637236595153809,
            "y": -10.423264503479004,
            "z": -10.743685722351074
        },
        {
            "x": 0.32599034905433655,
            "y": -10.53732967376709,
            "z": -10.670445442199707
        },
        {
            "x": 1.5593377351760864,
            "y": -10.200119972229004,
            "z": -10.88696575164795
        },
        {
            "x": 2.5230612754821777,
            "y": -9.469941139221191,
            "z": -11.355807304382324
        },
        {
            "x": 3.0505247116088867,
            "y": -8.473048210144043,
            "z": -11.995904922485352
        },
        {
            "x": 3.0505247116088867,
            "y": -7.3818135261535645,
            "z": -12.696578979492188
        },
        {
            "x": 2.5230612754821777,
            "y": -6.384920597076416,
            "z": -13.336676597595215
        },
        {
            "x": 1.5593377351760864,
            "y": -5.654742240905762,
            "z": -13.805519104003906
        },
        {
            "x": 0.32599034905433655,
            "y": -5.317532062530518,
            "z": -14.022038459777832
        },
        {
            "x": -0.9637236595153809,
            "y": -5.4315972328186035,
            "z": -13.948798179626465
        },
        {
            "x": -2.086801052093506,
            "y": -5.977214813232422,
            "z": -13.598461151123047
        },
        {
            "x": -2.8490517139434814,
            "y": -6.860042095184326,
            "z": -13.031604766845703
        },
        {
            "x": -1.8369700935892946e-15,
            "y": -8.104534149169922,
            "z": -12.622064590454102
        },
        {
            "x": -1.678155757022968e-15,
            "y": -8.104534149169922,
            "z": -12.622064590454102
        },
        {
            "x": -1.2291730260783804e-15,
            "y": -8.104534149169922,
            "z": -12.622064590454102
        },
        {
            "x": -5.676550114702304e-16,
            "y": -8.104534149169922,
            "z": -12.622064590454102
        },
        {
            "x": 1.9201567228831066e-16,
            "y": -8.104534149169922,
            "z": -12.622064590454102
        },
        {
            "x": 9.184850467946473e-16,
            "y": -8.104534149169922,
            "z": -12.622064590454102
        },
        {
            "x": 1.4861400582648777e-15,
            "y": -8.104534149169922,
            "z": -12.622064590454102
        },
        {
            "x": 1.7968279316694924e-15,
            "y": -8.104534149169922,
            "z": -12.622064590454102
        },
        {
            "x": 0,
            "y": 5.4030232429504395,
            "z": 8.41471004486084
        },
        {
            "x": -3.090169906616211,
            "y": 5.138580322265625,
            "z": 8.002863883972168
        },
        {
            "x": -2.5,
            "y": 3.610168933868408,
            "z": 8.984246253967285
        },
        {
            "x": -0.9549150466918945,
            "y": 2.6655590534210205,
            "z": 9.59077262878418
        },
        {
            "x": 0.9549150466918945,
            "y": 2.6655590534210205,
            "z": 9.59077262878418
        },
        {
            "x": 2.5,
            "y": 3.610168933868408,
            "z": 8.984246253967285
        },
        {
            "x": 3.090169906616211,
            "y": 5.138580322265625,
            "z": 8.002863883972168
        },
        {
            "x": 2.5,
            "y": 6.666991233825684,
            "z": 7.021482944488525
        },
        {
            "x": 0.9549150466918945,
            "y": 7.61160135269165,
            "z": 6.4149556159973145
        },
        {
            "x": -0.9549150466918945,
            "y": 7.61160135269165,
            "z": 6.4149556159973145
        },
        {
            "x": -2.5,
            "y": 6.666991233825684,
            "z": 7.021482944488525
        },
        {
            "x": -5.877852439880371,
            "y": 4.371137619018555,
            "z": 6.807643413543701
        },
        {
            "x": -4.755282402038574,
            "y": 1.4639266729354858,
            "z": 8.674342155456543
        },
        {
            "x": -1.8163563013076782,
            "y": -0.3328283131122589,
            "z": 9.828024864196777
        },
        {
            "x": 1.8163563013076782,
            "y": -0.3328283131122589,
            "z": 9.828024864196777
        },
        {
            "x": 4.755282402038574,
            "y": 1.4639266729354858,
            "z": 8.674342155456543
        },
        {
            "x": 5.877852439880371,
            "y": 4.371137619018555,
            "z": 6.807643413543701
        },
        {
            "x": 4.755282402038574,
            "y": 7.278348445892334,
            "z": 4.940944671630859
        },
        {
            "x": 1.8163563013076782,
            "y": 9.075102806091309,
            "z": 3.787261486053467
        },
        {
            "x": -1.8163563013076782,
            "y": 9.075102806091309,
            "z": 3.787261486053467
        },
        {
            "x": -4.755282402038574,
            "y": 7.278348445892334,
            "z": 4.940944671630859
        },
        {
            "x": -8.090169906616211,
            "y": 3.1758172512054443,
            "z": 4.946042060852051
        },
        {
            "x": -6.5450849533081055,
            "y": -0.8256149291992188,
            "z": 7.515332221984863
        },
        {
            "x": -2.5,
            "y": -3.2986361980438232,
            "z": 9.103240966796875
        },
        {
            "x": 2.5,
            "y": -3.2986361980438232,
            "z": 9.103240966796875
        },
        {
            "x": 6.5450849533081055,
            "y": -0.8256149291992188,
            "z": 7.515332221984863
        },
        {
            "x": 8.090169906616211,
            "y": 3.1758172512054443,
            "z": 4.946042060852051
        },
        {
            "x": 6.5450849533081055,
            "y": 7.177249431610107,
            "z": 2.3767521381378174
        },
        {
            "x": 2.5,
            "y": 9.650270462036133,
            "z": 0.7888436317443848
        },
        {
            "x": -2.5,
            "y": 9.650270462036133,
            "z": 0.7888436317443848
        },
        {
            "x": -6.5450849533081055,
            "y": 7.177249431610107,
            "z": 2.3767521381378174
        },
        {
            "x": -9.510564804077148,
            "y": 1.6696258783340454,
            "z": 2.6002883911132812
        },
        {
            "x": -7.69420862197876,
            "y": -3.0343399047851562,
            "z": 5.620669841766357
        },
        {
            "x": -2.9389262199401855,
            "y": -5.9415507316589355,
            "z": 7.487368583679199
        },
        {
            "x": 2.9389262199401855,
            "y": -5.9415507316589355,
            "z": 7.487368583679199
        },
        {
            "x": 7.69420862197876,
            "y": -3.0343399047851562,
            "z": 5.620669841766357
        },
        {
            "x": 9.510564804077148,
            "y": 1.6696258783340454,
            "z": 2.6002883911132812
        },
        {
            "x": 7.69420862197876,
            "y": 6.373591899871826,
            "z": -0.4200933873653412
        },
        {
            "x": 2.9389262199401855,
            "y": 9.280802726745605,
            "z": -2.286792039871216
        },
        {
            "x": -2.9389262199401855,
            "y": 9.280802726745605,
            "z": -2.286792039871216
        },
        {
            "x": -7.69420862197876,
            "y": 6.373591899871826,
            "z": -0.4200933873653412
        },
        {
            "x": -10,
            "y": 3.308397401318602e-16,
            "z": 5.152523652762774e-16
        },
        {
            "x": -8.090169906616211,
            "y": -4.946042060852051,
            "z": 3.1758172512054443
        },
        {
            "x": -3.090169906616211,
            "y": -8.002863883972168,
            "z": 5.138580322265625
        },
        {
            "x": 3.090169906616211,
            "y": -8.002863883972168,
            "z": 5.138580322265625
        },
        {
            "x": 8.090169906616211,
            "y": -4.946042060852051,
            "z": 3.1758172512054443
        },
        {
            "x": 10,
            "y": -6.996649904206947e-16,
            "z": 1.1769318455399978e-15
        },
        {
            "x": 8.090169906616211,
            "y": 4.946042060852051,
            "z": -3.1758172512054443
        },
        {
            "x": 3.090169906616211,
            "y": 8.002863883972168,
            "z": -5.138580322265625
        },
        {
            "x": -3.090169906616211,
            "y": 8.002863883972168,
            "z": -5.138580322265625
        },
        {
            "x": -8.090169906616211,
            "y": 4.946042060852051,
            "z": -3.1758172512054443
        },
        {
            "x": -10,
            "y": 2.3918493071160883e-15,
            "z": -8.081065952511633e-16
        },
        {
            "x": -9.510564804077148,
            "y": -1.6696258783340454,
            "z": -2.6002883911132812
        },
        {
            "x": -7.69420862197876,
            "y": -6.373591899871826,
            "z": 0.4200933873653412
        },
        {
            "x": -2.9389262199401855,
            "y": -9.280802726745605,
            "z": 2.286792039871216
        },
        {
            "x": 2.9389262199401855,
            "y": -9.280802726745605,
            "z": 2.286792039871216
        },
        {
            "x": 7.69420862197876,
            "y": -6.373591899871826,
            "z": 0.4200933873653412
        },
        {
            "x": 9.510564804077148,
            "y": -1.6696258783340454,
            "z": -2.6002883911132812
        },
        {
            "x": 7.69420862197876,
            "y": 3.0343399047851562,
            "z": -5.620669841766357
        },
        {
            "x": 2.9389262199401855,
            "y": 5.9415507316589355,
            "z": -7.487368583679199
        },
        {
            "x": -2.9389262199401855,
            "y": 5.9415507316589355,
            "z": -7.487368583679199
        },
        {
            "x": -7.69420862197876,
            "y": 3.0343399047851562,
            "z": -5.620669841766357
        },
        {
            "x": -8.090169906616211,
            "y": -3.1758172512054443,
            "z": -4.946042060852051
        },
        {
            "x": -6.5450849533081055,
            "y": -7.177249431610107,
            "z": -2.3767521381378174
        },
        {
            "x": -2.5,
            "y": -9.650270462036133,
            "z": -0.7888436317443848
        },
        {
            "x": 2.5,
            "y": -9.650270462036133,
            "z": -0.7888436317443848
        },
        {
            "x": 6.5450849533081055,
            "y": -7.177249431610107,
            "z": -2.3767521381378174
        },
        {
            "x": 8.090169906616211,
            "y": -3.1758172512054443,
            "z": -4.946042060852051
        },
        {
            "x": 6.5450849533081055,
            "y": 0.8256149291992188,
            "z": -7.515332221984863
        },
        {
            "x": 2.5,
            "y": 3.2986361980438232,
            "z": -9.103240966796875
        },
        {
            "x": -2.5,
            "y": 3.2986361980438232,
            "z": -9.103240966796875
        },
        {
            "x": -6.5450849533081055,
            "y": 0.8256149291992188,
            "z": -7.515332221984863
        },
        {
            "x": -5.877852439880371,
            "y": -4.371137619018555,
            "z": -6.807643413543701
        },
        {
            "x": -4.755282402038574,
            "y": -7.278348445892334,
            "z": -4.940944671630859
        },
        {
            "x": -1.8163563013076782,
            "y": -9.075102806091309,
            "z": -3.787261486053467
        },
        {
            "x": 1.8163563013076782,
            "y": -9.075102806091309,
            "z": -3.787261486053467
        },
        {
            "x": 4.755282402038574,
            "y": -7.278348445892334,
            "z": -4.940944671630859
        },
        {
            "x": 5.877852439880371,
            "y": -4.371137619018555,
            "z": -6.807643413543701
        },
        {
            "x": 4.755282402038574,
            "y": -1.4639266729354858,
            "z": -8.674342155456543
        },
        {
            "x": 1.8163563013076782,
            "y": 0.3328283131122589,
            "z": -9.828024864196777
        },
        {
            "x": -1.8163563013076782,
            "y": 0.3328283131122589,
            "z": -9.828024864196777
        },
        {
            "x": -4.755282402038574,
            "y": -1.4639266729354858,
            "z": -8.674342155456543
        },
        {
            "x": -3.090169906616211,
            "y": -5.138580322265625,
            "z": -8.002863883972168
        },
        {
            "x": -2.5,
            "y": -6.666991233825684,
            "z": -7.021482944488525
        },
        {
            "x": -0.9549150466918945,
            "y": -7.61160135269165,
            "z": -6.4149556159973145
        },
        {
            "x": 0.9549150466918945,
            "y": -7.61160135269165,
            "z": -6.4149556159973145
        },
        {
            "x": 2.5,
            "y": -6.666991233825684,
            "z": -7.021482944488525
        },
        {
            "x": 3.090169906616211,
            "y": -5.138580322265625,
            "z": -8.002863883972168
        },
        {
            "x": 2.5,
            "y": -3.610168933868408,
            "z": -8.984246253967285
        },
        {
            "x": 0.9549150466918945,
            "y": -2.6655590534210205,
            "z": -9.59077262878418
        },
        {
            "x": -0.9549150466918945,
            "y": -2.6655590534210205,
            "z": -9.59077262878418
        },
        {
            "x": -2.5,
            "y": -3.610168933868408,
            "z": -8.984246253967285
        },
        {
            "x": -1.2246467996456087e-15,
            "y": -5.4030232429504395,
            "z": -8.41471004486084
        },
        {
            "x": -9.907600388432518e-16,
            "y": -5.4030232429504395,
            "z": -8.41471004486084
        },
        {
            "x": -3.7843666549022706e-16,
            "y": -5.4030232429504395,
            "z": -8.41471004486084
        },
        {
            "x": 3.7843666549022706e-16,
            "y": -5.4030232429504395,
            "z": -8.41471004486084
        },
        {
            "x": 9.907600388432518e-16,
            "y": -5.4030232429504395,
            "z": -8.41471004486084
        },
        {
            "x": 1.2246467996456087e-15,
            "y": -5.4030232429504395,
            "z": -8.41471004486084
        },
        {
            "x": 0,
            "y": 2.7015116214752197,
            "z": 4.20735502243042
        },
        {
            "x": -2.9389262199401855,
            "y": 2.1855688095092773,
            "z": 3.4038217067718506
        },
        {
            "x": -0.9081781506538391,
            "y": -0.16641415655612946,
            "z": 4.914012432098389
        },
        {
            "x": 2.377641201019287,
            "y": 0.7319633364677429,
            "z": 4.3371710777282715
        },
        {
            "x": 2.377641201019287,
            "y": 3.639174222946167,
            "z": 2.4704723358154297
        },
        {
            "x": -0.9081781506538391,
            "y": 4.537551403045654,
            "z": 1.8936307430267334
        },
        {
            "x": -4.755282402038574,
            "y": 0.8348129391670227,
            "z": 1.3001441955566406
        },
        {
            "x": -1.4694631099700928,
            "y": -2.9707753658294678,
            "z": 3.7436842918395996
        },
        {
            "x": 3.84710431098938,
            "y": -1.5171699523925781,
            "z": 2.8103349208831787
        },
        {
            "x": 3.84710431098938,
            "y": 3.186795949935913,
            "z": -0.2100466936826706
        },
        {
            "x": -1.4694631099700928,
            "y": 4.640401363372803,
            "z": -1.143396019935608
        },
        {
            "x": -4.755282402038574,
            "y": -0.8348129391670227,
            "z": -1.3001441955566406
        },
        {
            "x": -1.4694631099700928,
            "y": -4.640401363372803,
            "z": 1.143396019935608
        },
        {
            "x": 3.84710431098938,
            "y": -3.186795949935913,
            "z": 0.2100466936826706
        },
        {
            "x": 3.84710431098938,
            "y": 1.5171699523925781,
            "z": -2.8103349208831787
        },
        {
            "x": -1.4694631099700928,
            "y": 2.9707753658294678,
            "z": -3.7436842918395996
        },
        {
            "x": -2.9389262199401855,
            "y": -2.1855688095092773,
            "z": -3.4038217067718506
        },
        {
            "x": -0.9081781506538391,
            "y": -4.537551403045654,
            "z": -1.8936307430267334
        },
        {
            "x": 2.377641201019287,
            "y": -3.639174222946167,
            "z": -2.4704723358154297
        },
        {
            "x": 2.377641201019287,
            "y": -0.7319633364677429,
            "z": -4.3371710777282715
        },
        {
            "x": -0.9081781506538391,
            "y": 0.16641415655612946,
            "z": -4.914012432098389
        },
        {
            "x": -6.123233998228043e-16,
            "y": -2.7015116214752197,
            "z": -4.20735502243042
        },
        {
            "x": -1.8921833274511353e-16,
            "y": -2.7015116214752197,
            "z": -4.20735502243042
        },
        {
            "x": 4.953800194216259e-16,
            "y": -2.7015116214752197,
            "z": -4.20735502243042
        }
    ]